
import React, { useState } from "react";
import { GlobalContext } from "./GlobalContext";
export const GlobalState =(props) => {
    const [notificationUnRead, setNotificationUnRead] = useState(false);
    const [managenotification, setManagenotification] = useState(false);
    const [fcmToken, setFcmToken] = useState('');
    const [resolveAllNotification, setResolveAllNotification] = useState(false);

    const value ={
        notificationUnRead,setNotificationUnRead,
        managenotification,setManagenotification,
        fcmToken,setFcmToken,
        resolveAllNotification,setResolveAllNotification
    }

    return (
        <GlobalContext.Provider value={value}>
            {props.children}
        </GlobalContext.Provider>
    )
}