import React, { useState, useEffect, useCallback } from "react";
import {
    Button,
    Col,
    Container,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { pineappleDexService } from "../../service/api.service";
import { debounce } from "lodash";
import { ShimmerThumbnail } from "react-shimmer-effects";


export const ContactUsListing = () => {
    const [contactUslist, setContactUsList] = useState([]);
    const [totalItems, setTotalItems] = useState();
    const [loader, setLoader] = useState(false);

    const [page, setPage] = useState(0);
    const [activePage, setActivePage] = useState(1);

    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);

    const [showPopUp, setShowPopUp] = useState(false);
    const [fullMessage, setFullMessage] = useState(null);


    useEffect(() => {
        setLoader(true);
        getContactUsData(0);
    }, []);


    const getContactUsData = async (page, search) => {
        try {
            let param = {
                page: page ? page : 0,
                keyword: search ? search : ''
            }
            const response = await pineappleDexService.getContactUsData(param);
            if (response?.status == 200) {
                let totalCount = response.data && response.data.totalCount ? response.data.totalCount : 0;
                let list = response.data && response.data.data ? response.data.data : [];
                setContactUsList(list);
                setTotalItems(totalCount);
                setLoader(false);
                setSearchLoader(false);
            }
        } catch (error) {
            setSearchLoader(false);
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                    }
                );
            }
        }
    };


    function handlePageChange(pageNumber) {
        let page = pageNumber - 1
        setPage(page);
        setActivePage(pageNumber);
        setLoader(true);
        getContactUsData(page);
    };


    const searchData = useCallback(debounce(getContactUsData, 1000), []);

    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12}>
                                <Row className="justify-content-between" >
                                    <Col md={7} className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <h2 className="align-self-center m-0 inner-details-heading">Contact Management</h2>
                                        </div>
                                    </Col>
                                    <Col md={5} className="search-area">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <form
                                                autoComplete="off"
                                                onSubmit={(event) => {
                                                    event.preventDefault();
                                                }}
                                            >
                                                <div className="position-relative width-inner-main">
                                                    <input
                                                        type="text"
                                                        value={search}
                                                        className="form-control inner-input"
                                                        placeholder="Search by name and email address..."
                                                        onChange={(e) =>
                                                        (
                                                            setSearchLoader(true),
                                                            searchData(page, e.target.value),
                                                            setSearch(e.target.value)
                                                        )
                                                        }
                                                        onKeyDown={(e) => {
                                                            if (!e.target.value && e.code === "Space") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    {
                                                        searchLoader ?
                                                            <span class="loader-search"></span>
                                                            :
                                                            <i
                                                                className="fa fa-search"
                                                                aria-hidden="true"
                                                            ></i>
                                                    }
                                                    <i className="fa fa-close d-none" aria-hidden="true"></i>
                                                </div>
                                            </form>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12}>
                                <div className="audit-request-box under-without-pagination ">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loader ?
                                                    (
                                                        <>
                                                            {[...Array(10)].map((_, index) => (
                                                                <tr key={index}>
                                                                    <td><ShimmerThumbnail /></td>
                                                                    <td><ShimmerThumbnail /></td>
                                                                    <td><ShimmerThumbnail /></td>
                                                                    <td><ShimmerThumbnail /></td>
                                                                    <td><ShimmerThumbnail /></td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )
                                                    :
                                                    contactUslist && contactUslist?.length > 0 ? (
                                                        contactUslist?.map((data, index) => {
                                                            const serialNumber = (activePage - 1) * 10 + index + 1;

                                                            return (
                                                                <tr key={index}>
                                                                    <td>{serialNumber}</td>
                                                                    <td>{data.name ? data.name : 'N/A'}</td>
                                                                    <td>{data.email ? data.email : 'N/A'}</td>
                                                                    <td className="width-wise-under">{data.subject ? data.subject : 'N/A'} </td>
                                                                    <td className="width-wise">{
                                                                        data.message ?
                                                                            <p className="view-more-area">{(data.message).slice(0, 80)}
                                                                                {
                                                                                    data.message && data.message.length > 80 ?
                                                                                        <span className="view-more" onClick={() => (setFullMessage(data.message), setShowPopUp(true))}>...see more</span>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </p>
                                                                            :
                                                                            'N/A'
                                                                    } </td>
                                                                </tr>
                                                            )
                                                        })) : (
                                                        <tr className="no-dat-found-box">
                                                            <td colSpan={10}>
                                                                <img
                                                                    src={require("../../assets/images/no-data.svg").default}
                                                                    alt="da"
                                                                />
                                                                <h3 className="text-center text-light">
                                                                    No data found
                                                                </h3>
                                                            </td>
                                                        </tr>
                                                    )}
                                        </tbody>
                                    </Table>
                                    {contactUslist.length > 0 && totalItems > 10 && !loader ?
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalItems}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                    <Modal
                                        show={showPopUp}
                                        onHide={() => setShowPopUp(false)}
                                        centered
                                        className='add-new-device-popup unde-text'
                                    >
                                        <Modal.Header>
                                            <p className="message-text">Message:</p>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="successfull-section">
                                                <p className="succefull-txt main-text">{fullMessage ? fullMessage : 'N/A'}</p>
                                                <Button
                                                    className="close-btn"
                                                    onClick={() => setShowPopUp(false)}
                                                >Close</Button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};
