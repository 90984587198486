import React, { useState, useEffect, useCallback } from "react";
import {
    Col,
    Container,
    Dropdown,
    Form,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { pineappleDexService } from "../../service/api.service";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from "react-icons/fa";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ReactSelectSearchableDropdown } from "../../common/reactSelectDropdown";
import { DateRangeFilter } from "../../common/dateRange";
import { OverLayAddressTooltip } from "../../common/Functions";
import userImg from "../../assets/images/byDefaultUser.png";
import moment from "moment";


export const TransactionsList = () => {

    const [copiedUserAddress, setUserAddress] = useState(false);
    const [copiedValue, setCopiedValue] = useState(false);
    const [copiedFrom, setCopiedFrom] = useState(false)
    const [copiedTo, setCopiedTo] = useState(false)
    const [copiedHash, setCopiedHash] = useState(false);
    const [mouseHoverWalletAddress, setMouseHoverWalletAddress] = useState('');
    const [mouseHoverAddressValue, setMouseHoverAddressValue] = useState('');
    const [mouseHoverToTokenValue, setMouseHoverToTokenValue] = useState('');
    const [mouseHoverTransactionHashValue, setMouseHoverTransactionHashValue] = useState('');

    const [transactionsList, setTransactionsList] = useState([]);
    const [totalItems, setTotalItems] = useState();
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [filter, setFilter] = useState({ name: "All", id: "" });
    const [statusFilter, setStatusFilter] = useState({ name: "All", id: "" });
    const [dateFilter, setDateFilter] = useState({ start: "", end: "" });

    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);

    //From and to filter
    const [tokensList, setTokensList] = useState([]);
    const [tokensList2, setTokensList2] = useState([]);
    const [selectedFromToken, setSelectedFromToken] = useState({ label: 'From - All', address: "", name: 'From - All' });
    const [selectedToToken, setSelectedToToken] = useState({ label: 'To - All', address: "", name: 'To - All' });

    const filtersList = [
        {
            name: "All",
            id: ""
        },
        {
            name: 'Swap',
            id: "SWAP"
        },
        {
            name: 'Limit Order',
            id: "LIMIT_SWAP"
        },
        {
            name: "Deposit",
            id: "DEPOSIT"
        }
    ];

    const statusFilterList = [
        {
            name: "All",
            id: ""
        },
        {
            name: "Pending",
            id: "PENDING"
        },
        {
            name: "Completed",
            id: "COMPLETE"
        },
        {
            name: "Failed",
            id: "FAILED"
        },
        {
            name: "Canceled",
            id: "CANCELLED",
        },
        {
            name: "Expired",
            id: "EXPIRED",
        },
    ];



    useEffect(() => {
        getTokenList();
    }, []);

    useEffect(() => {
        setLoader(true);
        getAllTransactionsHistory(page, search, dateFilter?.start, dateFilter?.end, selectedFromToken, selectedToToken, filter, statusFilter);
    }, [page, search, selectedFromToken, selectedToToken, statusFilter, dateFilter, filter]);

    const getTokenList = async () => {
        try {
            const response = await pineappleDexService.getTokensList();
            if (response?.status === 200) {
                let list = response.data && response.data.tokens ? response.data.tokens : [];
                let list2 = response.data && response.data.tokens ? response.data.tokens : [];
                // Prepare the list with "All" option
                list = [
                    {
                        "_id": 0,
                        "label": "From - All",
                        "address": "",
                        "symbol": "From - All",
                        "decimals": 18,
                        "name": "From - All",
                        "logoURI": "",
                        "eip2612": false,
                        "createdAt": "",
                        "updatedAt": "",
                        "__v": 0
                    },
                    ...list.map(token => ({ ...token, label: token.symbol })) // Ensure each token has a 'label'
                ];
                list2 = [
                    {
                        "_id": 1,
                        "label": "To - All",
                        "address": "",
                        "symbol": "To - All",
                        "decimals": 1,
                        "name": "To - All",
                        "logoURI": "",
                        "eip2612": false,
                        "createdAt": "",
                        "updatedAt": "",
                        "__v": 1
                    },
                    ...list2.map(token => ({ ...token, label: token.symbol }))
                ];
                setTokensList2(list2);           // To show from and to token selected as "From - All" & "To - All" values by default
                setTokensList(list);
            }
        } catch (error) {
        }
    };


    const getAllTransactionsHistory = async (page, search, startDate, endDate, fromToken, toToken, transactionType, transactionStatus) => {
        try {
            const params = {
                page: page,
                keyword: search,
                startDate: startDate,
                endDate: endDate,
                ft: fromToken?.address,
                tt: toToken?.address,
                transactionType: transactionType?.id,
                transactionStatus: transactionStatus?.id
            }
            const response = await pineappleDexService.getTransactions(params);
            if (response?.status == 200) {
                setTransactionsList(response?.data?.data)
                setTotalItems(response?.data?.totalCount);
                setLoader(false);
                setSearchLoader(false);
            } else {
                setLoader(false);
                throw new Error('Failed to fetch data');
            }
        }
        catch (error) {
            
            setLoader(false);
            setSearchLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                    }
                );
            }
        }
    };

    function handlePageChange(pageNumber) {
        let page = pageNumber - 1
        setPage(page);
        setActivePage(pageNumber);
        setLoader(true);
    };

    const handleFilters = async (item) => {
        setFilter(item);
    };

    const handleCopy = (address, type) => {
        if (type === "userAddress") {
            setUserAddress(true);
            setCopiedValue(address);
            setTimeout(() => setUserAddress(false), 2000);
        }
        else if (type === 'fromAddress') {
            setCopiedFrom(true);
            setCopiedValue(address);
            setTimeout(() => setCopiedFrom(false), 2000);
        }
        else if (type === 'toAddress') {
            setCopiedTo(true);
            setCopiedValue(address);
            setTimeout(() => setCopiedTo(false), 2000);
        }
        else if (type === 'hashAddress') {
            setCopiedHash(true);
            setCopiedValue(address);
            setTimeout(() => setCopiedHash(false), 2000);
        }
        else;
    };

    const searchData = useCallback(debounce(getAllTransactionsHistory, 1000), []);

    return (
        <>
            <div className="dashboard-main-area transactions-management-section">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12}>
                                <Row className="justify-content-between under-transaction">
                                    <Col md={3} className="d-flex ">
                                        <h2 className="align-self-center m-0">Transactions Management</h2>
                                    </Col>

                                    <Col md={9} className="search-area d-flex justify-content-end">

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="position-relative w-100 react-select-main me-2">
                                                <ReactSelectSearchableDropdown
                                                    placeholder="Select from token"
                                                    value={selectedFromToken}
                                                    openMenuOnFocus={true}
                                                    list={tokensList ? tokensList : []}
                                                    onChange={(item) => {
                                                        setSearch('');
                                                        setSelectedFromToken(item);
                                                    }}
                                                />
                                            </div>
                                            <div className="position-relative w-100 react-select-main me-2">
                                                <ReactSelectSearchableDropdown
                                                    placeholder="Select to token"
                                                    value={selectedToToken}
                                                    openMenuOnFocus={true}
                                                    list={tokensList2 ? tokensList2 : []}
                                                    onChange={(item) => {
                                                        setSearch('');
                                                        setSelectedToToken(item);
                                                    }}
                                                />
                                            </div>
                                            <form
                                                autoComplete="off"
                                                onSubmit={(event) => {
                                                    event.preventDefault();
                                                }}
                                            >
                                                <div className="position-relative">
                                                    <input
                                                        type="text"
                                                        value={search}
                                                        className="form-control inner-input"
                                                        placeholder="Search by hash, wallet & token address..."
                                                        onChange={(e) =>
                                                        (
                                                            setSearchLoader(true),
                                                            searchData(0, e.target.value, dateFilter?.start, dateFilter?.end, selectedFromToken, selectedToToken, filter, statusFilter),
                                                            setPage(0),
                                                            setSearch(e.target.value)
                                                        )
                                                        }
                                                        onKeyDown={(e) => {
                                                            if (!e.target.value && e.code === "Space") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    {
                                                        searchLoader ?
                                                            <span className="loader-search"></span>
                                                            :
                                                            <>
                                                                <i
                                                                    className="fa fa-search"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                <i className="fa fa-close d-none" aria-hidden="true"></i>
                                                            </>
                                                    }
                                                </div>
                                            </form>

                                            <div className="all-dropdown-list">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant={"secondary"}
                                                        id="dropdown-basic">
                                                        {filter?.name}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            filtersList.map((item, index) => (
                                                                filter?.name !== item.name &&
                                                                <Dropdown.Item
                                                                    onClick={() => handleFilters(item)}
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </Dropdown.Item>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="all-dropdown-list ms-2">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant={"secondary"}
                                                        id="dropdown-basic">
                                                        {statusFilter?.name}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            statusFilterList.map((item, index) => (
                                                                statusFilter?.name !== item.name &&
                                                                <Dropdown.Item
                                                                    onClick={() => setStatusFilter(item)}
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </Dropdown.Item>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12} className="mt-3">
                                        <div className="position-relative react-select-main">
                                            <DateRangeFilter dateFilter={dateFilter} setDateFilter={setDateFilter} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>User ID</th>
                                                <th>Wallet Address</th>
                                                <th>Transaction Type</th>
                                                <th>From Token</th>
                                                <th>From Amount</th>
                                                <th>To Token</th>
                                                <th>To Amount</th>
                                                <th>Transaction Fees</th>
                                                <th>Transaction Hash</th>
                                                <th>Transaction Date & Time</th>
                                                <th>Transaction Status</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loader ?
                                                    (
                                                        <>
                                                            {[...Array(10)].map((_, index) => (
                                                                <tr key={index}>
                                                                    {[...Array(11)].map((_, i) => <td key={i}><ShimmerThumbnail /></td>)}
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )
                                                    :
                                                    transactionsList && transactionsList?.length > 0 ? (
                                                        transactionsList?.map((data, index) => {

                                                            const fullUserWalletAddress = data.user && data.user?.walletAddress ? data.user.walletAddress : '';
                                                            const fullFromTokenAddress = data.fromToken && data.fromToken?.address ? data.fromToken?.address : '';
                                                            const fullToTokenAddress = data.toToken && data.toToken?.address ? data.toToken?.address : '';
                                                            const fullHashAddress = data.transactionHash ? data.transactionHash : '';

                                                            const formattedWalletAddress = data.user?.walletAddress
                                                                ? `${data.user.walletAddress.slice(0, 5)}...${data.user.walletAddress.slice(-5)}`
                                                                : 'N/A';
                                                            const formattedFromTokenAddress = data.fromToken?.address
                                                                ? `${data.fromToken?.address.slice(0, 5)}...${data.fromToken?.address.slice(-5)}`
                                                                : 'N/A';
                                                            const formattedToTokenAddress = data.toToken?.address
                                                                ? `${data.toToken?.address.slice(0, 5)}...${data.toToken?.address.slice(-5)}`
                                                                : 'N/A';
                                                            const formattedTransactionHash = data.transactionHash
                                                                ? `${data.transactionHash.slice(0, 5)}...${data.transactionHash.slice(-5)}`
                                                                : 'N/A';

                                                            const fromTokenToEth = Number(data?.fromAmount)
                                                            const toAmount = Number(data?.toAmount)
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{data?.userId ? data?.userId : "N/A"}</td>
                                                                    <td>
                                                                        <OverLayAddressTooltip overlayValue={fullUserWalletAddress}>
                                                                            <span
                                                                                style={mouseHoverWalletAddress == fullUserWalletAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "3px 4px" } : {}}
                                                                                onMouseEnter={() => setMouseHoverWalletAddress(fullUserWalletAddress)}
                                                                                onMouseLeave={() => setMouseHoverWalletAddress('')}
                                                                            >
                                                                                {formattedWalletAddress}
                                                                            </span>
                                                                        </OverLayAddressTooltip>
                                                                        {fullUserWalletAddress && (
                                                                            <>
                                                                                <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                    <CopyToClipboard text={fullUserWalletAddress} onCopy={() => handleCopy(index, "userAddress")}>
                                                                                        <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                            <FaCopy />
                                                                                        </button>
                                                                                    </CopyToClipboard>
                                                                                </OverLayAddressTooltip>
                                                                                {copiedUserAddress && (copiedValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                    <td>{data.transactionType}</td>
                                                                    <td>
                                                                        <div className="token-details">
                                                                            {data?.fromToken?.logoURI ? <img src={data?.fromToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} <span>{data?.fromToken?.symbol || "N/A"}
                                                                                <div className="d-flex">
                                                                                    <OverLayAddressTooltip overlayValue={fullFromTokenAddress} defaultShow={true}>
                                                                                        <span
                                                                                            style={mouseHoverAddressValue == fullFromTokenAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 2px" } : { borderRadius: '5px', padding: "1px 2px" }}
                                                                                            onMouseEnter={() => setMouseHoverAddressValue(fullFromTokenAddress)}
                                                                                            onMouseLeave={() => setMouseHoverAddressValue('')}
                                                                                        >
                                                                                            {formattedFromTokenAddress}
                                                                                        </span>
                                                                                    </OverLayAddressTooltip>
                                                                                    {fullFromTokenAddress && (
                                                                                        <>
                                                                                            <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                                <CopyToClipboard text={fullFromTokenAddress} onCopy={() => handleCopy(index, "fromAddress")}>
                                                                                                    <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                                        <FaCopy />
                                                                                                    </button>
                                                                                                </CopyToClipboard>
                                                                                            </OverLayAddressTooltip>
                                                                                            {copiedFrom && (copiedValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>{fromTokenToEth ? parseFloat(fromTokenToEth).toPrecision(10) : 'N/A'}</td>
                                                                    <td>
                                                                        <div className="token-details">
                                                                            {data?.toToken?.logoURI ? <img src={data?.toToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />}<span> {data?.toToken?.symbol || "N/A"}
                                                                                <div className="d-flex">
                                                                                    <OverLayAddressTooltip overlayValue={fullToTokenAddress}>
                                                                                        <span
                                                                                            style={mouseHoverToTokenValue == fullToTokenAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 2px" } : { borderRadius: '5px', padding: "1px 2px" }}
                                                                                            onMouseEnter={() => setMouseHoverToTokenValue(fullToTokenAddress)}
                                                                                            onMouseLeave={() => setMouseHoverToTokenValue('')}
                                                                                        >
                                                                                            {formattedToTokenAddress}
                                                                                        </span>
                                                                                    </OverLayAddressTooltip>
                                                                                    {fullToTokenAddress && (
                                                                                        <>
                                                                                            <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                                <CopyToClipboard text={data.toToken?.address} onCopy={() => handleCopy(index, "toAddress")}>
                                                                                                    <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                                        <FaCopy />
                                                                                                    </button>
                                                                                                </CopyToClipboard>
                                                                                            </OverLayAddressTooltip>
                                                                                            {copiedTo && (copiedValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>{toAmount ? parseFloat(toAmount).toPrecision(10) : 'N/A'}</td>
                                                                    <td>{data.transaction_fee ? data?.transaction_fee?.toFixed(15) : 'N/A'}</td>
                                                                    <td>
                                                                        <OverLayAddressTooltip overlayValue={fullHashAddress}>
                                                                            <span
                                                                                style={mouseHoverTransactionHashValue == fullHashAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "3px 4px" } : {}}
                                                                                onMouseEnter={() => setMouseHoverTransactionHashValue(fullHashAddress)}
                                                                                onMouseLeave={() => setMouseHoverTransactionHashValue('')}
                                                                            >
                                                                                {formattedTransactionHash}
                                                                            </span>
                                                                        </OverLayAddressTooltip>
                                                                        {fullHashAddress && (
                                                                            <>
                                                                                <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                    <CopyToClipboard text={fullHashAddress} onCopy={() => handleCopy(index, "hashAddress")}>
                                                                                        <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                            <FaCopy />
                                                                                        </button>
                                                                                    </CopyToClipboard>
                                                                                </OverLayAddressTooltip>
                                                                                {copiedHash && (copiedValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                    <td>{data.createdAt ? moment(data.createdAt).format("DD-MM-YYYY hh:mm A") : "N/A"}</td>
                                                                    <td>
                                                                        {(() => {
                                                                            switch (data.transactionStatus) {
                                                                            case "COMPLETE":
                                                                                return (
                                                                                <div className="completed-transation">
                                                                                    <i className="fa-solid fa-check me-1"></i> COMPLETE
                                                                                </div>
                                                                                );
                                                                            case "PENDING":
                                                                                return (
                                                                                <div className="completed-transation pending" >
                                                                                    <i className="fa-regular fa-clock me-1"></i> PENDING
                                                                                </div>
                                                                                );
                                                                            case "FAILED":
                                                                                return (
                                                                                <div className="completed-transation failed" >
                                                                                    <i className="fa-solid fa-xmark me-1"></i> FAILED
                                                                                </div>
                                                                                );
                                                                                case "EXPIRED":
                                                                                return (
                                                                                    <div className="completed-transation failed" >
                                                                                    <i className="fa-solid fa-xmark me-1"></i> EXPIRED
                                                                                    </div>
                                                                                );
                                                                            case "CANCELLED":
                                                                                return (
                                                                                <div className="completed-transation failed" >
                                                                                    <i className="fa-solid fa-xmark me-1"></i> CANCELLED
                                                                                </div>
                                                                                );
                                                                            default:
                                                                                return null;
                                                                            }
                                                                        })()}
                                                                    </td>
                                                                    {/* <td>
                                                                <Link
                                                                    to={`/transaction-detail`}
                                                                    state={{ userWallerAddress: data.walletAddress ? data.walletAddress : '' }}
                                                                >
                                                                    <div className="view-btn-main" style={{ cursor: 'pointer' }}>
                                                                        < i className="fa-solid fa-eye"></i>
                                                                    </div>
                                                                </Link>
                                                            </td> */}
                                                                </tr>
                                                            )
                                                        })) : (
                                                        <tr className="no-dat-found-box">
                                                            <td colSpan={12}>
                                                                <img
                                                                    src={
                                                                        require("../../assets/images/no-data.svg")
                                                                            .default
                                                                    }
                                                                    alt="da"
                                                                />
                                                                <h3 className="text-center text-light">
                                                                    No Data found
                                                                </h3>
                                                            </td>
                                                        </tr>
                                                    )}
                                        </tbody>
                                    </Table>
                                    {transactionsList.length > 0 && totalItems > 10 ?
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalItems}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};
