import axios from "axios";
import Big from "big.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";



export const OverLayAddressTooltip = (props) => {
    let overlayValue = props && props.overlayValue ? props.overlayValue : ''

    return (
        <OverlayTrigger
            // defaultShow={true}
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
                <Tooltip {...props}>
                    {overlayValue ? overlayValue : 'Address N/A'}
                </Tooltip>
            )}
            placement="top"
        >
            {props.children}
        </OverlayTrigger>
    )
};


//Function to get current dollar price in ETH
const getEthPriceInUSD = async () => {
    try {
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
        return response.data.ethereum.usd;
    } catch (error) {
        console.error('Error fetching ETH price:', error);
        return null;
    }
};


// Function to convert ETH to USD
export const convertEthToUsd = async (ethAmount) => {
    try {
        const ethPriceInUSD = await getEthPriceInUSD();
        if (ethPriceInUSD === null) {
            throw new Error('Failed to fetch ETH price');
        }
        const dollarAmount = ethAmount * ethPriceInUSD;
        return dollarAmount;
    } catch (error) {
        console.error('Error converting ETH to USD:', error);
        return null;
    }
};


export const handleTransactionFee = (val) => {
    try {
        let bigNumber = new Big(val);

        // Convert to a string with enough decimal places, then remove trailing zeros
        let fixedNumber = bigNumber.toFixed(20);
        let trimmedString = fixedNumber.replace(/\.?0+$/, '');

        return trimmedString;
    } catch (e) {
        // If there is an error (e.g., invalid input), return the original value
        return val;
    }
};