import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import userImg from "../../assets/images/byDefaultUser.png";
import { OverLayAddressTooltip } from "../../common/Functions";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { pineappleDexService } from "../../service/api.service";
import swal from "sweetalert";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useSearchParams } from "react-router-dom";
import moment from "moment";


export const OrderDetail = () => {
    const navigate = useNavigate();
    let params = useParams();
    let swapId = params && params.id ? params.id : ''

    const [loader, setLoader] = useState(false);

    const [orderDetail, setOrderDetail] = useState(null);
    const [copiedUserAddress, setCopiedUserAddress] = useState(false);
    const [copiedValue, setCopiedValue] = useState(null);
    const [copiedFrom, setCopiedFrom] = useState(false)
    const [copiedTo, setCopiedTo] = useState(false)
    const [copiedHash, setCopiedHash] = useState(false);
    const [query, set_query] = useSearchParams();
    const id = query.get('id');
    const [mouseHoverAddressValue, setMouseHoverAddressValue] = useState('');
    const [fromCopiedValue, setFromCopiedValue] = useState(null);
    const [toCopiedValue, setToCopied] = useState(null);
    


    useEffect(() => {
        setLoader(true);
        getOrderDetail();
    }, []);


    const getOrderDetail = async () => {
        try {
            let param = {
                transactionId: id || ''
            }
            const response = await pineappleDexService.getSwapDetail(param);
            if (response?.status == 200) {
                let data = response.data && response.data.data ? response.data.data : null;
                setOrderDetail(data);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    };


    const handleCopy = (address, type) => {
        switch (type) {
            case "userAddress":
                setCopiedUserAddress(true);
                break;
            case "fromAddress":
                setCopiedFrom(true);
                break;
            case "toAddress":
                setCopiedTo(true);
                break;
            case "hashAddress":
                setCopiedHash(true);
                break;
            case "fromToken":
                setFromCopiedValue(true);
                break;
            default:
                break;
        }
        setCopiedValue(address);
        setTimeout(() => {
            setCopiedUserAddress(false);
            setCopiedFrom(false);
            setCopiedTo(false);
            setCopiedHash(false);
            setFromCopiedValue(false);
            setToCopied(false)

        }, 2000);
    };

    console.log("orderDetail---------->", orderDetail);

    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12}>
                                <Row className="justify-content-between" >
                                    <Col md={12} className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="back-btn-main me-4" onClick={() => navigate("/limit-orders", { state: "from_swap_detail_screen" })}>
                                                <i className="fa-solid fa-arrow-left me-2"></i>
                                                Back
                                            </div>
                                            <h2 className="align-self-center m-0 inner-details-heading">Limit Order Detail</h2>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12}>
                                <div className="transaction-swap-details">
                                    <div className="wallet-address-body">
                                        {
                                            loader ?
                                                (
                                                    <>
                                                        {[...Array(4)].map((_, index) => (
                                                            <div className="wallet-info" key={index}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <ShimmerThumbnail />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <ShimmerThumbnail />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )
                                                :
                                                
                                                <>

                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            {/* <div className="col-md-6">
                                                                <label>Order ID</label>
                                                                <p className="toekn-main-paragraph">{orderDetail?.fromAmount ? orderDetail.fromAmount : "N/A"} {orderDetail?.usdAmount ? `($${orderDetail.usdAmount})` : ''}</p>
                                                            </div> */}
                                                            <div className="col-md-6">
                                                                <label>User Wallet Address</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{orderDetail?.userId?.walletAddress || "N/A" }</span>
                                                                    {
                                                                        orderDetail && orderDetail.userId && orderDetail.userId?.walletAddress &&
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard
                                                                                text={orderDetail.userId.walletAddress}
                                                                                onCopy={() => handleCopy(orderDetail.userId.walletAddress, "userAddress")}
                                                                            >
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy color="white" />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                    }
                                                                    {
                                                                        orderDetail && copiedUserAddress &&
                                                                        (copiedValue === orderDetail?.userId?.walletAddress) &&
                                                                        (<span style={{ marginLeft: '8px', color: '#62fc25' , fontSize: '12px' }}>Copied!</span>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Order Type</label>
                                                                <p className="toekn-main-paragraph">{orderDetail?.transactionType || orderDetail?.transactionType === 0 ? orderDetail.transactionType : 'N/A'}</p>

                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Order Amount</label>
                                                                <p className="toekn-main-paragraph">{Number(orderDetail?.toAmount) ? Number(orderDetail?.toAmount) : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Token Pair</label>
                                                                <p className="toekn-main-paragraph d-flex align-items-center">
                                                                    {orderDetail?.fromToken?.logoURI ? <img src={orderDetail?.fromToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />}
                                                                    <div>
                                                                        <span>{orderDetail?.fromToken?.symbol || ""}</span>
                                                                        <span className="toekn-main-paragraph">{orderDetail && orderDetail.fromToken && orderDetail.fromToken.address ? orderDetail.fromToken.address : 'N/A'}</span>
                                                                        {
                                                                            orderDetail && orderDetail.fromToken && orderDetail.fromToken.address &&
                                                                            <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                <CopyToClipboard
                                                                                    text={orderDetail.fromToken.address}
                                                                                    onCopy={() => handleCopy(orderDetail.fromToken.address, "fromAddress")}
                                                                                >
                                                                                    <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                        <FaCopy color="white" />
                                                                                    </button>
                                                                                </CopyToClipboard>
                                                                            </OverLayAddressTooltip>
                                                                        }
                                                                        {copiedFrom && (copiedValue === orderDetail?.fromToken?.address) && <span style={{ marginLeft: '8px', color: '#62fc25' , fontSize: '12px' }}>Copied!</span>}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Token Prize</label>
                                                                <p className="toekn-main-paragraph">{orderDetail?.fromAmount ? orderDetail.fromAmount : "N/A"} {orderDetail?.usdAmount ? `($${orderDetail.usdAmount})` : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Transaction Hash</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{orderDetail?.transactionHash ? orderDetail.transactionHash : 'N/A'}</span>
                                                                    {
                                                                        orderDetail?.transactionHash &&
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard
                                                                                text={orderDetail.transactionHash}
                                                                                onCopy={() => handleCopy(orderDetail.transactionHash, "hashAddress")}
                                                                            >
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy color="white" />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                    }
                                                                    {copiedHash && (copiedValue === orderDetail?.transactionHash) && <span style={{ marginLeft: '8px', color: '#62fc25' , fontSize: '12px' }}>Copied!</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Order Expiry</label>
                                                                <p className="toekn-main-paragraph">{orderDetail?.expireDateTime ? moment(orderDetail?.expireDateTime).format('DD MMM YYYY, hh:mm A') : 'N/A'}</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Created Date & Time</label>
                                                                <p className="toekn-main-paragraph">{orderDetail?.createdAt ? moment(orderDetail?.createdAt).format('DD MMM YYYY, hh:mm A') : 'N/A'}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Order Status</label>
                                                                <p className="toekn-main-paragraph">{orderDetail?.transactionStatus ||  'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* change */}
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>FromToken Name & Symbol</label>
                                                                <p className="toekn-main-paragraph d-flex align-items-center"> 
                                                                    <img src={orderDetail?.fromToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle logo-url" /> 
                                                                {orderDetail?.fromToken?.name} ({orderDetail?.fromToken?.symbol || 'N/A'})</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>toToken Name & Symbol</label>
                                                                <p className="toekn-main-paragraph d-flex align-items-center"> 
                                                                    <img src={orderDetail?.toToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle logo-url" />
                                                                {orderDetail?.toToken?.name} ({orderDetail?.toToken?.symbol || 'N/A'})</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>FromToken LogoUrl</label>
                                                                {orderDetail?.fromToken?.logoURI ?
                                                                <>
                                                                    <img src={orderDetail?.fromToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle logo-url" /> {orderDetail?.fromToken?.name} 
                                                                </>
                                                                :
                                                                 <img src={userImg} alt="image" height={50} width={50} className="rounded-circle logo-url" />}
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>toToken LogoUrl</label>
                                                                {orderDetail?.toToken?.logoURI ?
                                                                <>
                                                                <img src={orderDetail?.toToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle logo-url" /> {orderDetail?.toToken?.name}
                                                                </>
                                                                :
                                                                 <img src={userImg} alt="image" height={50} width={50} className="rounded-circle logo-url" />}
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Amendment Detail</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">New Price : {'N/A'}</span>
                                                                </div>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">New Amount : {'N/A'}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Cancellation Detail</label>
                                                                <p className="toekn-main-paragraph"> Date and Time  : {'N/A'}</p>
                                                                <p className="toekn-main-paragraph"> Reason  : {'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Execution Details</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">Execution price : {'N/A'}</span>
                                                                </div>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">Amount : {'N/A'}</span>
                                                                </div>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">Date & Time : {'N/A'}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};
