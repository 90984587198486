import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Form,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { pineappleDexService } from "../../service/api.service";
import userImg from "../../assets/images/byDefaultUser.png";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { debounce } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { OverLayAddressTooltip } from "../../common/Functions";
import { ReactSelectSearchableDropdown } from "../../common/reactSelectDropdown";
import moment from "moment";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { getItem, setItem } from "../../util/localStorage";


export const SwapVolume = () => {
  const [swapListing, setSwapListing] = useState([]);
  const [swapData, setSwapData] = useState(null);
  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState("");

  //From and to filter
  const [tokensList, setTokensList] = useState([]);
  const [tokensList2, setTokensList2] = useState([]);
  const [selectedFromToken, setSelectedFromToken] = useState({ label: 'From - All', id: 0, name: 'From - All' });
  const [selectedToToken, setSelectedToToken] = useState({ label: 'To - All', id: 0, name: 'To - All' });

  const [copiedUserAddress, setUserAddress] = useState(false);
  const [copiedValue, setCopiedValue] = useState(false);
  const [copiedFrom, setCopiedFrom] = useState(false)
  const [copiedTo, setCopiedTo] = useState(false)
  const [copiedHash, setCopiedHash] = useState(false);

  //On address :hover functionality
  const [mouseHoverWalletAddress, setMouseHoverWalletAddress] = useState('');
  const [mouseHoverAddressValue, setMouseHoverAddressValue] = useState('');
  const [mouseHoverToTokenValue, setMouseHoverToTokenValue] = useState('');
  const [mouseHoverTransactionHashValue, setMouseHoverTransactionHashValue] = useState('');

  const [search, setSearch] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);

  const [page, setPage] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const { state } = useLocation();


  useEffect(() => {
    let searchValue = getItem("swap_volum_search");                  //set search from local storage | when the user search and navigate to the user swap detail screen and after back on list the search will show filled as previous search
    if (state === "from_swap_detail_screen" && searchValue) {
      setSearch(searchValue);
      setLoader(true);
      getTotalSwapVolume(0, searchValue);
      getTokenList();
    }
    else {
      setLoader(true);
      getTotalSwapVolume(0);
      getTokenList();
    }
  }, []);


  const getTotalSwapVolume = async (page, search, filter, fromToken, toToken) => {
    try {
      const params = {
        page: page ? page : 0,
        keyword: search ? search : '',
        filter: filter ? filter : '',
        ft: fromToken && fromToken.address ? fromToken.address : '',    // From token filter
        tt: toToken && toToken.address ? toToken.address : '',
        transactionType: "SWAP"          // To token filter
      };

      const response = await pineappleDexService.SwapVolume(params);

      if (response?.status === 200) {
        let list = response.data && response.data.data ? response.data.data : [];
        let totalCount = response.data && response.data.totalCount ? response.data.totalCount : 0;
        let totalVolume = response.data.totalVolume ? response.data.totalVolume : 0;
        let highestSwap = response.data.highestSwap ? response.data.highestSwap : 0

        let averageVolumeCount = totalCount > 0 ? (totalVolume / totalCount) : 0;

        // Format averageVolumeCount
        let formattedAverageVolumeCount;
        if (Number.isInteger(averageVolumeCount)) {
          formattedAverageVolumeCount = averageVolumeCount.toString();
        } else {
          formattedAverageVolumeCount = parseFloat(averageVolumeCount).toFixed(6);
        }

        // Format totalVolume
        let formatedTotalVolume;
        if (Number.isInteger(totalVolume)) {
          formatedTotalVolume = totalVolume.toString();
        } else {
          formatedTotalVolume = parseFloat(totalVolume).toFixed(6);
        }

        // Format totalCount
        let formatedTotalSwap;
        if (Number.isInteger(totalCount)) {
          formatedTotalSwap = totalCount.toString();
        } else {
          formatedTotalSwap = parseFloat(totalCount).toFixed(6);
        }

        // Format highestSwap
        let formatedHighestSwap;
        if (Number.isInteger(highestSwap)) {
          formatedHighestSwap = highestSwap.toString();
        } else {
          formatedHighestSwap = parseFloat(highestSwap).toFixed(6);
        }

        let swapData = {
          totalVolume: formatedTotalVolume,
          totalSwap: formatedTotalSwap,
          averageSwap: formattedAverageVolumeCount,
          highestSwap: formatedHighestSwap,
        };


        //Set search to local storage 
        if (search) {
          setItem("swap_volum_search", search);
        }
        else {
          localStorage.removeItem("swap_volum_search");
        }

        setSwapData(swapData);
        setSwapListing(list);
        setTotalItems(totalCount);
        setLoader(false);
        setSearchLoader(false);
      }
    }
    catch (error) {
      setLoader(false);
      setSearchLoader(false);
      if (error?.response?.status === 401) {
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      }
      else {
        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        });
      }
    }
  };


  const getTokenList = async () => {
    try {
      const response = await pineappleDexService.getTokensList();
      if (response?.status === 200) {
        let list = response.data && response.data.tokens ? response.data.tokens : [];
        let list2 = response.data && response.data.tokens ? response.data.tokens : [];
        // Prepare the list with "All" option
        list = [
          {
            "_id": 0,
            "label": "From - All",
            "address": "",
            "symbol": "From - All",
            "decimals": 18,
            "name": "From - All",
            "logoURI": "",
            "eip2612": false,
            "createdAt": "",
            "updatedAt": "",
            "__v": 0
          },
          ...list.map(token => ({ ...token, label: token.symbol })) // Ensure each token has a 'label'
        ];
        list2 = [
          {
            "_id": 1,
            "label": "To - All",
            "address": "",
            "symbol": "To - All",
            "decimals": 1,
            "name": "To - All",
            "logoURI": "",
            "eip2612": false,
            "createdAt": "",
            "updatedAt": "",
            "__v": 1
          },
          ...list2.map(token => ({ ...token, label: token.symbol }))
        ];
        setTokensList2(list2);           // To show from and to token selected as "From - All" & "To - All" values by default
        setTokensList(list);
      }
    } catch (error) {
      console.log('TOKEN LIST API ERROR:', error);
    }
  };


  function handlePageChange(pageNumber) {
    let page = pageNumber - 1
    setPage(page);
    setActivePage(pageNumber);
    setLoader(true);
    getTotalSwapVolume(page, search, filter, selectedFromToken, selectedToToken);
  };


  const handleFilterChange = (filterVal) => {
    setFilter(filterVal);
    setLoader(true);
    getTotalSwapVolume(page, search, filterVal, selectedFromToken, selectedToToken);
  };


  const handleCopy = (address, type) => {
    if (type === "userAddress") {
      setUserAddress(true);
      setCopiedValue(address);
      setTimeout(() => setUserAddress(false), 2000);
    }
    else if (type === 'fromAddress') {
      setCopiedFrom(true);
      setCopiedValue(address);
      setTimeout(() => setCopiedFrom(false), 2000);
    }
    else if (type === 'toAddress') {
      setCopiedTo(true);
      setCopiedValue(address);
      setTimeout(() => setCopiedTo(false), 2000);
    }
    else if (type === 'hashAddress') {
      setCopiedHash(true);
      setCopiedValue(address);
      setTimeout(() => setCopiedHash(false), 2000);
    }
    else;
  };

  const searchData = useCallback(debounce(getTotalSwapVolume, 1000), []);

  return (
    <>
      <div className="dashboard-main-area swap-reporting-section">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Swap Reporting</h2>
                  </div>
                  <div className="d-flex">
                    <div className="me-2">
                      <div className="d-flex">
                        <div className="position-relative w-100 me-2 react-select-main">
                          <ReactSelectSearchableDropdown
                            placeholder="Select from token"
                            value={selectedFromToken}
                            openMenuOnFocus={true}
                            list={tokensList ? tokensList : []}
                            onChange={(item) => {
                              setSearch('');
                              setSelectedFromToken(item);
                              getTotalSwapVolume(page, '', filter, item, selectedToToken)
                            }}
                          />
                        </div>
                        <div className="position-relative w-100 react-select-main">
                          <ReactSelectSearchableDropdown
                            placeholder="Select to token"
                            value={selectedToToken}
                            openMenuOnFocus={true}
                            list={tokensList2 ? tokensList2 : []}
                            onChange={(item) => {
                              setSearch('');
                              setSelectedToToken(item);
                              getTotalSwapVolume(page, '', filter, selectedFromToken, item)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="search-area d-flex main-search-area">
                      <form
                        autoComplete="off"
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <div className="position-relative">
                          <input
                            type="text"
                            value={search}
                            placeholder="Search by wallet and token address..."
                            className="form-control inner-input"
                            onChange={(e) =>
                            (
                              setSearchLoader(true),
                              setSelectedFromToken(''),
                              setSelectedToToken(''),
                              setSearch(e.target.value),
                              searchData(page, e.target.value, filter)
                            )
                            }
                            onKeyDown={(e) => {
                              if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                              }
                            }}
                          />
                          {
                            searchLoader ?
                              <span class="loader-search"></span>
                              :
                              <>
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                ></i>
                                <i className="fa fa-close d-none" aria-hidden="true"></i>
                              </>
                          }
                        </div>
                      </form>

                      <div className="filter-area">
                        <div className="select-days position-relative">
                          <Form.Select
                            aria-label="Default select example"
                            value={filter}
                            onChange={(e) => handleFilterChange(e.target.value)}
                          >
                            <option value="">All</option>
                            <option value="24h">Last 24 Hours</option>
                            <option value="48h">Last 48 Hours</option>
                            <option value="7d">Last 7 Days</option>
                            <option value="15d">Last 15 Days</option>
                            <option value="30d">Last 30 Days</option>
                          </Form.Select>
                          <i className="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="margin50">
              <Col md={3}>
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-cart-shopping"></i></div>
                      <div>
                        <h5>Total Volume</h5>
                        <h3>{swapData && swapData.totalVolume ? `$${swapData.totalVolume}` : '0'}</h3>
                      </div>
                    </div>
                }
              </Col>
              <Col md={3}>
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-coins"></i></div>
                      <div>
                        <h5>Total Swap</h5>
                        <h3>{swapData && swapData.totalSwap}</h3>
                      </div>
                    </div>
                }
              </Col>
              <Col md={3}>
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-shuffle"></i></div>
                      <div>
                        <h5>Average Swap</h5>
                        <h3>{swapData && swapData.averageSwap ? `$${swapData.averageSwap}` : '0'}</h3>
                      </div>
                    </div>
                }
              </Col>
              <Col md={3}>
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-road"></i></div>
                      <div>
                        <h5>Highest Swap</h5>
                        <h3>{swapData && swapData.highestSwap ? `$${swapData.highestSwap}` : '0'}</h3>
                      </div>
                    </div>
                }
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Wallet Address</th>
                        <th>From Token</th>
                        <th>From Amount</th>
                        <th>To Token</th>
                        <th>To Amount</th>
                        <th>Date & Time</th>
                        <th>TransactionHash</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loader ?
                          (
                            <>
                              {[...Array(10)].map((_, index) => (
                                <tr key={index}>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                </tr>
                              ))}
                            </>
                          )
                          :
                          swapListing && swapListing.length > 0 ? (
                            swapListing.map((data, index) => {
                              const serialNumber = (activePage - 1) * 10 + index + 1;

                              const fullUserWalletAddress = data.user && data.user.walletAddress ? data.user.walletAddress : '';
                              const fullFromTokenAddress = data.fromToken && data.fromToken.address ? data.fromToken.address : '';
                              const fullToTokenAddress = data.toToken && data.toToken.address ? data.toToken.address : '';
                              const fullHashAddress = data.transactionHash ? data.transactionHash : '';

                              const formattedWalletAddress = data.user?.walletAddress
                                ? `${data.user.walletAddress.slice(0, 5)}...${data.user.walletAddress.slice(-5)}`
                                : 'N/A';
                              const formattedFromTokenAddress = data.fromToken?.address
                                ? `${data.fromToken.address.slice(0, 5)}...${data.fromToken.address.slice(-5)}`
                                : 'N/A';
                              const formattedToTokenAddress = data.toToken?.address
                                ? `${data.toToken.address.slice(0, 5)}...${data.toToken.address.slice(-5)}`
                                : 'N/A';
                              const formattedTransactionHash = data.transactionHash
                                ? `${data.transactionHash.slice(0, 5)}...${data.transactionHash.slice(-5)}`
                                : 'N/A';

                              return (
                                <tr key={index}>
                                  <td>{serialNumber}</td>
                                  <td>
                                    <OverLayAddressTooltip overlayValue={fullUserWalletAddress}>
                                      <span
                                        style={mouseHoverWalletAddress == fullUserWalletAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "3px 4px" } : {}}
                                        onMouseEnter={() => setMouseHoverWalletAddress(fullUserWalletAddress)}
                                        onMouseLeave={() => setMouseHoverWalletAddress('')}
                                      >
                                        {formattedWalletAddress}
                                      </span>
                                    </OverLayAddressTooltip>
                                    {fullUserWalletAddress && (
                                      <>
                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                          <CopyToClipboard text={fullUserWalletAddress} onCopy={() => handleCopy(index, "userAddress")}>
                                            <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                              <FaCopy />
                                            </button>
                                          </CopyToClipboard>
                                        </OverLayAddressTooltip>
                                        {copiedUserAddress && (copiedValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    <div className="token-details">
                                      {data?.fromToken?.logoURI ? <img src={data?.fromToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} <span>{data?.fromToken?.symbol || "N/A"}
                                        <div className="d-flex">
                                          <OverLayAddressTooltip overlayValue={fullFromTokenAddress} defaultShow={true}>
                                            <span
                                              style={mouseHoverAddressValue == fullFromTokenAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 2px" } : { borderRadius: '5px', padding: "1px 2px" }}
                                              onMouseEnter={() => setMouseHoverAddressValue(fullFromTokenAddress)}
                                              onMouseLeave={() => setMouseHoverAddressValue('')}
                                            >
                                              {formattedFromTokenAddress}
                                            </span>
                                          </OverLayAddressTooltip>
                                          {fullFromTokenAddress && (
                                            <>
                                              <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                <CopyToClipboard text={fullFromTokenAddress} onCopy={() => handleCopy(index, "fromAddress")}>
                                                  <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                    <FaCopy />
                                                  </button>
                                                </CopyToClipboard>
                                              </OverLayAddressTooltip>
                                              {copiedFrom && (copiedValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                            </>
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{data.fromAmount ? data.fromAmount : 'N/A'}</td>
                                  <td>
                                    <div className="token-details">
                                      {data?.toToken?.logoURI ? <img src={data?.toToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />}<span> {data?.toToken?.symbol || "N/A"}
                                        <div className="d-flex">
                                          <OverLayAddressTooltip overlayValue={fullToTokenAddress}>
                                            <span
                                              style={mouseHoverToTokenValue == fullToTokenAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 2px" } : { borderRadius: '5px', padding: "1px 2px" }}
                                              onMouseEnter={() => setMouseHoverToTokenValue(fullToTokenAddress)}
                                              onMouseLeave={() => setMouseHoverToTokenValue('')}
                                            >
                                              {formattedToTokenAddress}
                                            </span>
                                          </OverLayAddressTooltip>
                                          {fullToTokenAddress && (
                                            <>
                                              <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                <CopyToClipboard text={data.toToken.address} onCopy={() => handleCopy(index, "toAddress")}>
                                                  <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                    <FaCopy />
                                                  </button>
                                                </CopyToClipboard>
                                              </OverLayAddressTooltip>
                                              {copiedTo && (copiedValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                            </>
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{data.toAmount ? data.toAmount : 'N/A'}</td>
                                  <td>{data.createdAt ? moment(data.createdAt).format("DD-MM-YYYY hh:mm A") : "N/A"}</td>
                                  <td>
                                    <OverLayAddressTooltip overlayValue={fullHashAddress}>
                                      <span
                                        style={mouseHoverTransactionHashValue == fullHashAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "3px 4px" } : {}}
                                        onMouseEnter={() => setMouseHoverTransactionHashValue(fullHashAddress)}
                                        onMouseLeave={() => setMouseHoverTransactionHashValue('')}
                                      >
                                        {formattedTransactionHash}
                                      </span>
                                    </OverLayAddressTooltip>
                                    {fullHashAddress && (
                                      <>
                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                          <CopyToClipboard text={fullHashAddress} onCopy={() => handleCopy(index, "hashAddress")}>
                                            <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                              <FaCopy />
                                            </button>
                                          </CopyToClipboard>
                                        </OverLayAddressTooltip>
                                        {copiedHash && (copiedValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    <OverLayAddressTooltip overlayValue={"View"}>
                                      <Link to={`/swap-detail/${data._id}`}>
                                        <div className="view-btn-main">
                                          < i className="fa-solid fa-eye"></i>
                                        </div>
                                      </Link>
                                    </OverLayAddressTooltip>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="no-data-image"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                              </td>
                            </tr>
                          )}
                    </tbody>
                  </Table>
                  {swapListing.length > 0 && totalItems > 10 && !loader ? (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
