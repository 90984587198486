import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react';
import { pineappleDexService } from '../../service/api.service';
import swal from "sweetalert";
import { LineChart } from '@mui/x-charts/LineChart';
import { FaCopy } from 'react-icons/fa';
import { OverLayAddressTooltip } from '../../common/Functions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DateRangeFilter } from "../../common/dateRange";
import moment from 'moment';

  
export const Home = () => {
    const [dateFilter, setDateFilter] = useState({
        start: moment().subtract(1, 'years').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD')
      });
    const filtersList = [
        {
            name: "All",
            id: ""
        },
        {
            name: 'This Month',
            id: "1M"
        },
        {
            name: 'Last 3 Months',
            id: "3M"
        },
        {
            name: '1 Year',
            id: "1Y"
        }
    ];

    const [filter, setFilter] = useState({ name: "1 Year", id: "1Y" });
    const [data, setData] = useState({});
    const [mouseHoverAddressValue, setMouseHoverAddressValue] = useState('');
    const [copiedUserAddress, setUserAddress] = useState(false);

    useEffect(() => {
        getAdminDashboardData();
    }, [filter, dateFilter?.start, dateFilter?.end]);

    const getAdminDashboardData = async () => {
        try {
            const params = {
                filter:filter.id,
                startDate:dateFilter?.start,
                endDate:dateFilter?.end,
            }
            const response = await pineappleDexService.getDashboardData(params);
            if (response?.status == 200) {
                setData(response?.data?.data);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                    }
                );
            }
        }
    }

    const handleCopy = () => {
        setUserAddress(true);
        setTimeout(() => {
            setUserAddress(false);
        }, 2000);
    }

    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="all-dropdown-list mb-2 text-right row align-items-center">
                        <Col md={6} className="mt-3">
                            <div className="position-relative react-select-main">
                                <DateRangeFilter dateFilter={dateFilter} setDateFilter={setDateFilter} />
                            </div>
                        </Col>
                        <Col md={6}>
                        <Dropdown>
                            <Dropdown.Toggle
                                variant={"secondary"}
                                id="dropdown-basic">
                                {filter?.name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    filtersList.map((item, index) => (
                                        filter?.name !== item.name &&
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => setFilter(item)}
                                        >
                                            {item.name}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        </Col>
                       
                    </div>
                    <div className="dashboard-area-heading management-heading under-dashboard">
                        <Row>
                            <Col md={3}>
                                <div className="card-infor-box">
                                    <div className="main-icon-1"><i className="fa-solid fa-cart-shopping"></i></div>
                                    <div>
                                        <h5>Total Swap</h5>
                                        <h3>{data?.totalSwap}</h3>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="card-infor-box">
                                    <div className="main-icon-1"><i className="fa-solid fa-coins"></i></div>
                                    <div>
                                        <h5>Total Users</h5>
                                        <h3>{data?.totalUser}</h3>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="card-infor-box">
                                    <div className="main-icon-1"><i className="fa-solid fa-shuffle"></i></div>
                                    <div>
                                        <h5>Total Active Users</h5>
                                        <h3>{data?.totalActiveUser}</h3>
                                    </div>
                                </div>

                            </Col>
                            <Col md={3}>
                                <div className="card-infor-box">
                                    <div className="main-icon-1"><i className="fa-solid fa-road"></i></div>
                                    <div>
                                        <h5>Fee Collected 
                                        <span>
                                            (<OverLayAddressTooltip overlayValue={data?.platfromWalletAddress} defaultShow={true}>
                                                <span
                                                    style={mouseHoverAddressValue == data?.platfromWalletAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 2px" } : { borderRadius: '5px', padding: "1px 2px" }}
                                                    onMouseEnter={() => setMouseHoverAddressValue(data?.platfromWalletAddress)}
                                                    onMouseLeave={() => setMouseHoverAddressValue('')}
                                                >
                                                    {data?.platfromWalletAddress ? `${data?.platfromWalletAddress.slice(0, 5)}...${data?.platfromWalletAddress.slice(-5)}`: 'N/A'}
                                                </span>
                                            </OverLayAddressTooltip>
                                            <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                <CopyToClipboard text={data?.platfromWalletAddress} onCopy={() => handleCopy()}>
                                                    <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer', color: '#fff' }}>
                                                        <FaCopy />
                                                    </button>
                                                </CopyToClipboard>
                                            </OverLayAddressTooltip>
                                           )
                                    <div className='copy-text'>{copiedUserAddress && <span className="error-text" style={{ marginLeft: '0px', color: '#62fc25', position: 'static' }}>Copied!</span>}</div>
                                    </span>
                                    </h5>
                                        <h3>$ {data && data?.totalFee ? data.totalFee?.toFixed(15): 0}</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* pricong graph here */}
                        <Row>
                        <Col md={12}>
                            {data ? (
                                <div className='pricing-graph mt-4'>
                                    <LineChart
                                        xAxis={[{ 
                                            scaleType: "point", 
                                            data: [...Array.from({ length: data?.graphData?.price?.length }, (_, i) => i+1)], 
                                            valueFormatter: (v) => data?.graphData?.date[v-1] 
                                        }]}
                                        yAxis={[
                                            {
                                                valueFormatter: (value) => value.toFixed(10),
                                            }
                                        ]}
                                        colors={["#FCE025"]}
                                        series={[
                                            {
                                                data: data?.graphData?.price?.map((item) => item ? item.toFixed(10):0) || [],
                                                // label: "Pricing",
                                                showMark: true
                                            },
                                        ]}
                                        height={600}
                                        sx={{
                                            // bottomAxis Line Styles 
                                            "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
                                                stroke:"#fff",
                                            },
                                            // leftAxis Line Styles
                                            "& .MuiChartsAxis-left .MuiChartsAxis-line":{
                                                stroke:"#fff",
                                            },
                                            // change tick label styles
                                            "& .MuiChartsAxis-tick": {
                                                stroke: "#fff !important",
                                            },
                                            // change legend styles
                                            "& text": {
                                                fill: "#fff !important",
                                            },
                                            "& .css-1u2hdaw-MuiChartsAxisHighlight-root" : {
                                                stroke: "#fff",
                                            }
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="no-data-found">No data found</div>
                            )}
                        </Col>
                    </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};
