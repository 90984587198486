import { createContext } from "react";
// export const baseUrl = "http://192.168.1.77:3000"  // local
// export const baseUrl = "https://staging-api.pineappledex.com"  // staging
export const baseUrl = "https://test-api.pineappledex.com"  // test
// export const baseUrl ="https://api.pineappledex.com"   // live



export const config = {
  apiUrl: baseUrl + "/api/v1",
  imageUrl: baseUrl + '/',
}

export const DATE_OPTIONS = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}

export const MyContext = createContext();
export const formatWalletAddress = (address) => {
  if (!address) return '';
  const firstPart = address.slice(0, 4);
  const lastPart = address.slice(-4);
  return `${firstPart}...${lastPart}`;
};