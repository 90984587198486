import React from "react"
import moment from "moment";

export const DateRangeFilter = ({ dateFilter, setDateFilter }) => {

    const todayDate = moment(new Date()).format("YYYY-MM-DD");

    const changeStartDate = (date) => {
        if (dateFilter.end && date <= dateFilter.end && date <= todayDate) {
            setDateFilter({ ...dateFilter, start: date })
        } else if (!dateFilter.end && date <= todayDate) {
            setDateFilter({ ...dateFilter, start: date })
        }
    }

    const changeEndDate = (date) => {
        if (date <= todayDate && date >= dateFilter.start) setDateFilter({ ...dateFilter, end: date })
    }

    return (
        <div className="system-log-area-date-picker d-flex">
            <div className="under-date-component position-relative">
                <label>Start Date</label>
                <input type="date" max={dateFilter.end ? dateFilter.end : todayDate} value={dateFilter.start} onChange={(e) => changeStartDate(e.target.value)} />
                <i className="fa-solid fa-calendar-days"></i>
            </div>
            <div className="under-date-component position-relative">
                <label>Last Date</label>
                <input type="date" min={dateFilter.start} max={todayDate} value={dateFilter.end} onChange={(e) => changeEndDate(e.target.value)} />

                {/* <input type="date" min={dateFilter.start} max={todayDate} disabled={dateFilter.start ? false : true} value={dateFilter.end} onChange={(e) => changeEndDate(e.target.value)} /> */}
                <i className="fa-solid fa-calendar-days"></i>
            </div>
        </div>
    )
}