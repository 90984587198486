import { Col, Container, Row, Table, Button } from "react-bootstrap";
import { pineappleDexService } from "../../service/api.service";
import swal from "sweetalert";
import { useEffect, useState } from "react";
import ConfigurationModal from "../../common/configurationModal/ConfigurationModal";


const Configuration = () => {
    const [loader, setLoader] = useState(false);
    const [configurationList, setConfigurationList] = useState();
    const [configruationDetail, setConfigruationDetail] = useState({
        eventType: "",
        priority: "",
        range: "",
        time: "",
        timeType: "",
    });
    const [showModal, setShowModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        getConfigurationList();
    }, []);

    /* Get configuration list */
    async function getConfigurationList() {
        setLoader(true);
        try {
            const response = await pineappleDexService.getConfigurationData();
            if (response.status === 200) {
                setConfigurationList(response.data?.data);
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoader(false);
        }
    }

    function clearState() {
        setConfigruationDetail({
            eventType: "",
            priority: "",
            range: "",
            time: "",
            timeType: "",
        })
    }

    /* Add or update configuration */
    async function manageConfiguration(data, configruationDetail) {
        setLoader(true);

        // Function to get only updated keys
        const getUpdatedKeys = (newData, oldData) => {
            return Object.keys(newData).reduce((acc, key) => {
                if (newData[key] !== oldData[key]) {
                    acc[key] = newData[key];
                }
                return acc;
            }, {});
        };

        try {
            const updatedData = getUpdatedKeys(data, configruationDetail);

            if (Object.keys(updatedData).length > 0) {
                const response = await pineappleDexService.updateConfigurationData(updatedData, configruationDetail);
                if (response.status === 200) {
                    swal({ icon: "success", text: "Data updated successfully.", button: "OK" });
                    getConfigurationList();
                    clearState();
                }
            } else {
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoader(false);
        }
    }


    /* Handle API Errors */
    function handleApiError(error) {
        setLoader(false);
        if (error?.response?.status === 401) {
            swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                localStorage.clear();
                window.location.href = "/";
            });
        } else {
            swal({
                icon: 'error',
                text: error?.response?.data?.message || error?.message,
                button: "OK"
            });
        }
    }

    /* Open modal for adding a new configuration */
    const handleAddClick = () => {
        setConfigruationDetail({
            eventType: "",
            priority: "",
            range: "",
            time: "",
            timeType: "",
        });
        setIsEdit(false);
        setShowModal(true);
    };

    /* Open modal for editing an existing configuration */
    const handleEditClick = (config) => {
        setConfigruationDetail(config);
        setIsEdit(true);
        setShowModal(true);
    };

    const handleSave = (data) => {
        manageConfiguration(data, configruationDetail);
    };

    const getPriorityClass = (priority) => {
        switch (priority) {
            case 'HIGH':
                return 'priority-high';
            case 'MEDIUM':
                return 'priority-medium';
            case 'LOW':
                return 'priority-low';
            default:
                return '';
        }
    };

    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12}>
                                <Row className="justify-content-between">
                                    <Col md={7} className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <h2 className="align-self-center m-0 inner-details-heading">Configuration</h2>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12}>
                                <div className="transaction-swap-details">
                                    <div className="wallet-address-body">
                                        <Table responsive="sm" className="UserListTable">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Event Type</th>
                                                    <th>Priority</th>
                                                    <th>Range</th>
                                                    <th>Time</th>
                                                    {/* <th>TimeType</th> */}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {configurationList?.map((config, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{config.eventType || "-"}</td>
                                                            <td>
                                                                <div className={getPriorityClass(config.priority)}>
                                                                    {config.priority || "-"}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {config.range}
                                                                {config.eventType === "HIGH_TRADING" || config.eventType === "TRANSACTIONS_FAILURES" ? " %" : ""}
                                                            </td>

                                                            <td>{config.time + " " + config.timeType || "-"}</td>
                                                            <td>
                                                                <Button
                                                                    variant="warning"
                                                                    onClick={() => handleEditClick(config)}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>

                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </div>

            <ConfigurationModal
                show={showModal}
                handleClose={() => { setShowModal(false); clearState(); }}
                configData={configruationDetail}
                isEdit={isEdit}
                handleSave={handleSave}
            />
        </>
    );
};

export default Configuration;
