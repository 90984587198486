import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import userImg from "../../assets/images/byDefaultUser.png";
import { handleTransactionFee, OverLayAddressTooltip } from "../../common/Functions";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { pineappleDexService } from "../../service/api.service";
import swal from "sweetalert";
import { ShimmerThumbnail } from "react-shimmer-effects";


export const SwapDetail = () => {
    const navigate = useNavigate();
    let params = useParams();
    let swapId = params && params.id ? params.id : ''

    const [loader, setLoader] = useState(false);

    const [swapDetail, setSwapDetail] = useState(null);
    const [copiedUserAddress, setCopiedUserAddress] = useState(false);
    const [copiedValue, setCopiedValue] = useState(null);
    const [copiedFrom, setCopiedFrom] = useState(false)
    const [copiedTo, setCopiedTo] = useState(false)
    const [copiedHash, setCopiedHash] = useState(false);


    useEffect(() => {
        setLoader(true);
        getSwapDetail();
    }, []);


    const getSwapDetail = async () => {
        try {
            let param = {
                transactionId: swapId ? swapId : ''
            }
            const response = await pineappleDexService.getSwapDetail(param);
            if (response?.status == 200) {
                let data = response.data && response.data.data ? response.data.data : null;
                setSwapDetail(data);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    };


    const handleCopy = (address, type) => {
        switch (type) {
            case "userAddress":
                setCopiedUserAddress(true);
                break;
            case "fromAddress":
                setCopiedFrom(true);
                break;
            case "toAddress":
                setCopiedTo(true);
                break;
            case "hashAddress":
                setCopiedHash(true);
                break;
            default:
                break;
        }
        setCopiedValue(address);
        setTimeout(() => {
            setCopiedUserAddress(false);
            setCopiedFrom(false);
            setCopiedTo(false);
            setCopiedHash(false);
        }, 2000);
    };


    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12}>
                                <Row className="justify-content-between" >
                                    <Col md={12} className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="back-btn-main me-4" onClick={() => navigate("/swap-volume", { state: "from_swap_detail_screen" })}>
                                                <i className="fa-solid fa-arrow-left me-2"></i>
                                                Back
                                            </div>
                                            <h2 className="align-self-center m-0 inner-details-heading">Swap Transaction Detail</h2>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12}>
                                <div className="transaction-swap-details">
                                    <div className="wallet-address-body">
                                        {
                                            loader ?
                                                (
                                                    <>
                                                        {[...Array(4)].map((_, index) => (
                                                            <div className="wallet-info" key={index}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <ShimmerThumbnail />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <ShimmerThumbnail />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )
                                                :
                                                <>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Wallet Address</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{swapDetail && swapDetail.userId && swapDetail.userId.walletAddress ? swapDetail.userId.walletAddress : 'N/A'}</span>
                                                                    {
                                                                        swapDetail && swapDetail.userId && swapDetail.userId.walletAddress &&
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard
                                                                                text={swapDetail.userId.walletAddress}
                                                                                onCopy={() => handleCopy(swapDetail.userId.walletAddress, "userAddress")}
                                                                            >
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy color="white" />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                    }
                                                                    {
                                                                        swapDetail && copiedUserAddress &&
                                                                        (copiedValue === swapDetail?.userId?.walletAddress) &&
                                                                        (<span style={{ marginLeft: '8px', color: '#62fc25', fontSize: '12px' }}>Copied!</span>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>From Token</label>
                                                                <p className="toekn-main-paragraph d-flex align-items-center">
                                                                    {swapDetail?.fromToken?.logoURI ? <img src={swapDetail?.fromToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />}
                                                                    <div>
                                                                        <span>{swapDetail?.fromToken?.symbol || ""}</span>
                                                                        <span className="toekn-main-paragraph">{swapDetail && swapDetail.fromToken && swapDetail.fromToken.address ? swapDetail.fromToken.address : 'N/A'}</span>
                                                                        {
                                                                            swapDetail && swapDetail.fromToken && swapDetail.fromToken.address &&
                                                                            <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                <CopyToClipboard
                                                                                    text={swapDetail.fromToken.address}
                                                                                    onCopy={() => handleCopy(swapDetail.fromToken.address, "fromAddress")}
                                                                                >
                                                                                    <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                        <FaCopy color="white" />
                                                                                    </button>
                                                                                </CopyToClipboard>
                                                                            </OverLayAddressTooltip>
                                                                        }
                                                                        {copiedFrom && (copiedValue === swapDetail?.fromToken?.address) && <span style={{ marginLeft: '8px', color: '#62fc25', fontSize: '12px' }}>Copied!</span>}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>From Amount</label>
                                                                <p className="toekn-main-paragraph">{swapDetail?.fromAmount ? swapDetail.fromAmount : "N/A"} {swapDetail?.usdAmount ? `($${swapDetail.usdAmount})` : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>To Token</label>
                                                                <p className="toekn-main-paragraph d-flex align-items-center">
                                                                    {swapDetail?.toToken?.logoURI ? <img src={swapDetail?.toToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />}
                                                                    <div>
                                                                        <span>{swapDetail?.toToken?.symbol || ""}</span>
                                                                        <span className="toekn-main-paragraph">{swapDetail?.toToken && swapDetail.toToken?.address ? swapDetail.toToken.address : 'N/A'}</span>
                                                                        {
                                                                            swapDetail?.toToken && swapDetail.toToken.address &&
                                                                            <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                <CopyToClipboard
                                                                                    text={swapDetail.toToken.address}
                                                                                    onCopy={() => handleCopy(swapDetail.toToken.address, "toAddress")}
                                                                                >
                                                                                    <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                        <FaCopy color="white" />
                                                                                    </button>
                                                                                </CopyToClipboard>
                                                                            </OverLayAddressTooltip>
                                                                        }
                                                                        {copiedTo && (copiedValue === swapDetail?.toToken?.address) && <span style={{ marginLeft: '8px', color: '#62fc25', fontSize: '12px'  }}>Copied!</span>}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>To Amount</label>
                                                                <p className="toekn-main-paragraph">{swapDetail?.toAmount ? swapDetail.toAmount : 'N/A'} {swapDetail?.toUsdAmount ? `($${swapDetail.toUsdAmount})` : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Transaction Hash</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{swapDetail?.transactionHash ? swapDetail.transactionHash : 'N/A'}</span>
                                                                    {
                                                                        swapDetail?.transactionHash &&
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard
                                                                                text={swapDetail.transactionHash}
                                                                                onCopy={() => handleCopy(swapDetail.transactionHash, "hashAddress")}
                                                                            >
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy color="white" />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                    }
                                                                    {copiedHash && (copiedValue === swapDetail?.transactionHash) && <span style={{ marginLeft: '8px', color: '#62fc25' , fontSize: '12px' }}>Copied!</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Transaction Fee {`(ETH)`}</label>
                                                                <p className="toekn-main-paragraph">{swapDetail?.transaction_fee || swapDetail?.transaction_fee === 0 ? handleTransactionFee(swapDetail.transaction_fee) : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};
