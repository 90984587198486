import { Col, Container, Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { pineappleDexService } from "../../service/api.service";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import moment from "moment";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../globalState/GlobalContext";
import { useContext } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

const NotificationSecurity = () => {
    const [loader, setLoader] = useState(false);
    const [NotificationData, setNotificationData] = useState([]);
    const globalStates = useContext(GlobalContext);
    const [page, setPage] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [priorityFilter, setPriorityFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [sortOrder, setSortOrder] = useState('');

    useEffect(() => {
        getNotificationData(page, priorityFilter, statusFilter,sortOrder);
    }, [page, priorityFilter, statusFilter, sortOrder, globalStates.resolveAllNotification, globalStates.managenotification]);

    async function getNotificationData(page, priority, status,sortOrder) {
        setLoader(true);
        try {
            const response = await pineappleDexService.getNotificationList(page, priority, status,sortOrder);
            if (response.status === 200) {
                console.log("response", response);
                setNotificationData(response.data?.data);
                setTotalItems(response.data?.data?.totalDocuments);
                setLoader(false);
            }
        } catch (error) {
            handleApiError(error);
            setLoader(false);
        } finally {
            setLoader(false);
        }
    }
    const tooltip_view = (
        <Tooltip id="tooltip">
         View Details
        </Tooltip>
      );
    function handleApiError(error) {
        setLoader(false);
        if (error?.response?.status === 401) {
            swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                localStorage.clear();
                window.location.href = "/";
            });
        } else {
            swal({
                icon: 'error',
                text: error?.response?.data?.message || error?.message,
                button: "OK"
            });
        }
    }

    const handlePageChange = (pageNumber) => {
        let page = pageNumber - 1;
        setPage(page);
        setActivePage(pageNumber);
    };

    const handlePriorityChange = (e) => {
        setPriorityFilter(e.target.value);
    };

    const handleStatusChange = (e) => {
        setStatusFilter(e.target.value);
    };

    const handleOrderStatus = (e) => {
        setSortOrder(e.target.value);
    };

    const getPriorityClass = (priority) => {
        switch (priority) {
            case 'HIGH':
                return 'priority-high';
            case 'MEDIUM':
                return 'priority-medium';
            case 'LOW':
                return 'priority-low';
            default:
                return '';
        }
    };

    async function handleMarkAsResolved(alertId, status) {
        const params = {
            id: alertId,
            all: status
        }
        // setLoader(true);
        try {
            const response = await pineappleDexService.resolveNotification(params);
            if (response.status === 200) {
                swal({ icon: "success", text: "Notification status updated successfully.", button: "OK" });
                globalStates.setManagenotification(!globalStates.managenotification);
                getNotificationData(page, priorityFilter, statusFilter, sortOrder);
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoader(false);
        }
    }
    

    return (
        <div className="dashboard-main-area">
            <Container fluid>
                <div className="dashboard-area-heading management-heading">
                    <Row className="row justify-content-center align-items-center mb-3">
                        <Col md={12}>
                            <Row className="justify-content-between">
                                <Col md={7} className="d-flex justify-content-between w-100">
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                        <h2 className="align-self-center m-0 inner-details-heading">Security Alert & Notifications</h2>
                                        <div className="secuity-filter-tp-right">
                                            <div className="select-days position-relative">
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    value={priorityFilter}
                                                    onChange={handlePriorityChange}
                                                >
                                                        <option value="">All</option>
                                                    <option value="LOW">Low</option>
                                                    <option value="MEDIUM">Medium</option>
                                                    <option value="HIGH">High</option>
                                                </Form.Select>
                                                <i className="fa-solid fa-chevron-down"></i>
                                            </div>
                                            <div className="select-days position-relative ml-2">
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    value={statusFilter}
                                                    onChange={handleStatusChange}
                                                >
                                                    <option value="">All</option>
                                                    <option value="true">Resolved</option>
                                                    <option value="false">Mark as Resolve</option>
                                                </Form.Select>
                                                <i className="fa-solid fa-chevron-down"></i>
                                            </div>
                                            <div className="select-days position-relative ml-2">
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    value={sortOrder}
                                                    onChange={handleOrderStatus}
                                                >
                                                      <option value="">All</option>
                                                    <option value="desc">Desc</option>
                                                    <option value="asc">Asc</option>
                                                </Form.Select>
                                                <i className="fa-solid fa-chevron-down"></i>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pb-4 justify-content-center">
                        <Col md={12}>
                            <div className="audit-request-box under-without-pagination security-notifications">
                                <Table responsive="sm" className="UserListTable">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Alert Type</th>
                                            <th>Alert Title</th>
                                            <th>Priority</th>
                                            <th>Country</th>
                                            <th>Date & Time</th>
                                            <th>View</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                             loader ?
                                             (
                                                 <>
                                                     {[...Array(10)].map((_, index) => (
                                                         <tr key={index}>
                                                             <td><ShimmerThumbnail /></td>
                                                             <td><ShimmerThumbnail /></td>
                                                             <td><ShimmerThumbnail /></td>
                                                             <td><ShimmerThumbnail /></td>
                                                             <td><ShimmerThumbnail /></td>
                                                             <td><ShimmerThumbnail /></td>
                                                             <td><ShimmerThumbnail /></td>
                                                             <td><ShimmerThumbnail /></td>
                                                         </tr>
                                                     ))}
                                                 </>
                                             ) :
                                         NotificationData?.eventAlert?.length > 0 ?
                                         NotificationData?.eventAlert?.map((alert, index) => {
                                              const serialNumber = (activePage - 1) * 10 + index + 1;
                                            //   console.log("alert:::::::::::::::::", alert?.eventCategorizationId?.eventType == "API_KEY_USAGE" ? alert?.apiRequestIds[0]?.userSessionId?.country                                                :  "-");
                                            return (
                                                <tr key={index}>
                                                    <td>{serialNumber}</td>
                                                    <td>{alert?.eventCategorizationId?.eventType || "-"}</td>
                                                    <td>{alert?.subject || "-"}</td>
                                                    <td>
                                                      <div className={getPriorityClass(alert?.eventCategorizationId?.priority)} >
                                                      {alert?.eventCategorizationId?.priority || "-"}
                                                        </div> 
                                                    </td>
                                                    <td>{alert?.eventCategorizationId?.eventType == "ABNORMAL_LOGIN" ? alert?.countries[0] :
                                                    alert?.eventCategorizationId?.eventType == "API_KEY_USAGE" ? alert?.apiRequestIds[0]?.userSessionId?.country :
                                                    alert?.eventCategorizationId?.eventType == "RAPID_ACCOUNT" ? alert?.userIds[0]?.country : 
                                                    alert?.eventCategorizationId?.eventType == "TRANSACTIONS_FAILURES" ? alert?.userIds[0]?.country : 
                                                    alert?.eventCategorizationId?.eventType == "HIGH_TRADING" ? alert?.userIds[0]?.country : "-"}</td>
                                                    <td>{alert?.createdAt ? moment(alert?.createdAt).format("DD-MM-YYYY hh:mm A") : "-"}</td>
                                                    <td>
                                                    <OverlayTrigger placement="top" overlay={tooltip_view}>
                                                        <Link
                                                            to={`/notification-security-detail/${alert._id}`}
                                                            state={{ alertType: alert.alertType ? alert.alertType : '', userId: alert._id ? alert._id : '' }}
                                                        >
                                                            <div className="view-btn-main" style={{ cursor: 'pointer' }}>
                                                                <i className="fa-solid fa-eye"></i>
                                                            </div>
                                                        </Link>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        {alert?.isRead ? (
                                                            <span className="resolved-text">Resolved</span>
                                                        ) : (
                                                            <button
                                                                onClick={() => handleMarkAsResolved(alert._id, false)}
                                                                className="mark-as-resolved-btn"
                                                            >
                                                                Mark as Resolve
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        }):
                                        (
                                            <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="no-data-image"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                              </td>
                            </tr>
                                        )
                                        }
                                    </tbody>

                                </Table>
                                {totalItems > 10 && !loader ? (
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={10}
                                        totalItemsCount={totalItems}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                        prevPageText={"Prev"}
                                        nextPageText={"Next"}
                                    />
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default NotificationSecurity;
