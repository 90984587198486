import React from "react";
import { Link, useLocation } from "react-router-dom";
import { OverLayAddressTooltip } from "./Functions";


const Sidebar = ({ isOpen, setIsOpen }) => {
  const location = useLocation()

  return (
    <>
      <div className="sidebar-overlay">
        <section className="sidebar">
          <Link to="/home" className="logo">
            <img className="" src={require("../assets/images/logo-login.svg").default} alt="" />
          </Link>
          <div className="under-tabs">
            <Link to="/home" className={(location.pathname == "/home") || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/dashboard-1.svg").default}
                alt=""
              />
              Dashboard
            </Link>
            <Link to="/user-List" className={(location.pathname == "/user-List") || (location.pathname?.startsWith("/user-activities")) || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/audit.svg").default}
                alt=""
              />
              User Management
            </Link>
            <Link to="/swap-volume" className={(location.pathname == "/swap-volume") || (location.pathname?.startsWith("/swap-detail")) || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/pie-chart 1 (1).svg").default}
                alt=""
              />
              Swap Reporting
            </Link>
            <Link to="/popular-pair" className={(location.pathname == "/popular-pair") || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/earning 1 (1).svg").default}
                alt=""
              />
              Popular Pair
            </Link>
            {/* <OverLayAddressTooltip overlayValue={"Coming Soon"}>
              <div className={(location.pathname == "/swap") || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
                <img
                  src={require("../assets/images/exchange 1 (1).svg").default}
                  alt=""
                />
                Per Token Swap
              </div>
            </OverLayAddressTooltip>
            <OverLayAddressTooltip overlayValue={"Coming Soon"}>
              <div className={(location.pathname == "/report") || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
                <img
                  src={require("../assets/images/report 1.svg").default}
                  alt=""
                />
                Report
              </div>
            </OverLayAddressTooltip>
            <OverLayAddressTooltip overlayValue={"Coming Soon"}>
              <div className={(location.pathname == "/revenue") || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
                <img
                  src={require("../assets/images/earning 1 (1).svg").default}
                  alt=""
                />
                Revenue
              </div>
            </OverLayAddressTooltip> */}
            <Link to="/limit-orders" className={(location.pathname == "/limit-orders") || (location.pathname?.startsWith("/limit-order-detail")) || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/order-1.svg").default}
                alt=""
              />
              Limit Orders Reporting
            </Link>
            <Link to="/transactions" className={(location.pathname == "/transactions") || (location.pathname?.startsWith("/transaction-detail")) || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/transaction-main.svg").default}
                alt=""
              />
              Transactions Management
            </Link>
            <Link to="/token-management" className={location.pathname?.startsWith("/token-management") || (location.pathname?.startsWith("/token/")) ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/order-1.svg").default}
                alt=""
              />
              Token Management
            </Link>
            <Link to="/contact-us" className={(location.pathname == "/contact-us") || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/contact-us.svg").default}
                alt=""
              />
              Contact Management
            </Link>
            <Link to="/notification-security" className={(location.pathname == "/notification-security") || (location.pathname?.startsWith("/notification-security-detail")) || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/alert-notification.svg").default}
                alt=""
              />
             Security Alert & Notifications
            </Link>
            <Link to="/configuration" className={(location.pathname == "/configuration") || (location.pathname.split("/")[1] == "userInfo") ? "sidebar_option active" : "sidebar_option"} >
              <img
                src={require("../assets/images/configuration.svg").default}
                alt=""
              />
             Configuration
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};
export default Sidebar;
