import { Col, Container, Row, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { pineappleDexService } from "../../service/api.service";
import copy from "copy-to-clipboard";
import swal from "sweetalert";
import Swal from "sweetalert2";
import user from "../../assets/images/byDefaultUser.png"
import { config } from "../../config/config";
export function UserInfo() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [copyedToken, setCopyedToken] = useState(false)
  const [copyedStripeId, setCopyedStripeId] = useState(false)
  const copyToClipboard = (stripeId) => {
    copy(stripeId);
    setCopyedStripeId(true);
    setTimeout(() => {
      setCopyedStripeId(false);
    }, 1000);
  };

  const copyToClipboardToken = (VerificationToken) => {
    copy(VerificationToken);
    setCopyedToken(true);
    setTimeout(() => {
      setCopyedToken(false);
    }, 1000);
  }
  useEffect(() => {
    userDetail()
  }, [])

  async function userDetail() {
    setLoader(true)
    try {
      const response = await pineappleDexService.GetUserProfile(id)
      if (response?.status == 200) {
        setLoader(false)
        setData(response?.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)
        // Swal.fire({
        //   icon: 'error',
        //   title: error?.response?.statusText,
        //   text: error?.response?.data?.message,
        //   confirmButtonText: 'OK', // Add OK button
        //   iconHtml: '<i class="fa fa-exclamation-triangle" style="color:red;"></i>',
        // }).then(() => {
        //   localStorage.clear()
        //   window.location.href = "/";
        // });
        swal({icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
       });
      } else {
        setLoader(false)
        // Swal.fire({
        //   icon: 'error',
        //   title: error?.response?.statusText ? error?.response?.statusText : null,
        //   text: error?.response?.data?.message ? error?.response?.data?.message : error?.message,
        //   confirmButtonText: 'OK', // Add OK button
        //   iconHtml: '<i class="fa fa-exclamation-triangle" style="color:red;"></i>',
        // }).then(() => {
        // });
        swal({icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
          }
        );
      }
    }
  }

  return (
    <>
      <div className="dashboard-main-area">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="pb-4 mb-2 justify-content-center">
              <Col md={11}>
                <h2>User Details</h2>
              </Col>
              <Row className="pb-4">
                <Col md={9}>

                  <div className="user-info-area" style={{ display: "block", backgroundColor: "bisque" }}>
                    {data ?
                      <>
                        <Row className="row d-flex align-items-center mt-4 mb-5">
                          <Col className=" d-flex align-items-center user-detail-profile">
                            {data?.data?.user?.image ? <img src={config.imageUrl + data?.data?.user?.image} height={150} width={150} alt="image" className="d-block m-auto rounded-circle " style={{border:"1px solid #f5841e"}}/>:<img src={user} height={150} alt="image" width={150} className="d-block m-auto rounded-circle" style={{border:"1px solid #f5841e"}}/>}
                          </Col>

                        </Row>
                        <Row className="mb-4">
                          <Col>
                            <p className="user_detailField">First Name:</p>
                            <p className="user_detail">{data?.data?.user?.first_name ? <>{data?.data?.user?.first_name}</>:<>-</>}</p>
                          </Col>
                          <Col>
                            <p className="user_detailField">Last Name:</p>
                            <p className="user_detail">{data?.data?.user?.last_name ? <>{data?.data?.user?.last_name}</>:<>-</>}</p>
                          </Col>
                          <Col>
                            <p className="user_detailField">Email:</p>
                            <p className="user_detail">{data?.data?.user?.email ? <>{data?.data?.user?.email}</>:<>-</>}</p>

                          </Col>
                        </Row>
                        <Row className="mb-4">
                        <Col>
                            <p className="user_detailField">Trade Name:</p>
                            <p className="user_detail">{data?.data?.trade_name ? <>{data?.data?.trade_name}</>:<>-</>}</p>
                          </Col>
                          <Col>
                            <p className="user_detailField">LTFRB Number:</p>
                            <p className="user_detail">{data?.data?.ltfrb_number !== "undefined" ? <>{data?.data?.ltfrb_number}</>:<>-</>}</p>
                          </Col>
                          
                          <Col>
                            <p className="user_detailField">Marine Insurance:</p>
                            <p className="user_detail">{data?.data?.marine_insurance !=="undefined" ? <>{data?.data?.marine_insurance}</>:<>-</>}</p>

                          </Col>
                        </Row>
                        
                        <Row className="mb-4">
                          <Col>
                            <p className="user_detailField">Bank Name:</p>
                            <p className="user_detail">{data?.data?.bank_detail?.bank_name ? <>{data?.data?.bank_detail?.bank_name}</>:<>-</>}</p>
                          </Col>
                          <Col>
                            <p className="user_detailField">Account Number:</p>
                            <p className="user_detail">{data?.data?.bank_detail?.last4 ? <>{"......."+data?.data?.bank_detail?.last4}</>:<>-</>}</p>
                          </Col>
                          <Col>
                            <p className="user_detailField">DTI/Sec Number:</p>
                            <p className="user_detail">{data?.data?.dti_sec_number !=="undefined" ? <>{data?.data?.dti_sec_number}</>:<>-</>}</p>
                          </Col>
                          
                        </Row >
                        <Row className="mb-4">
                        <Col>
                            <p className="user_detailField">Permit Number:</p>
                            <p className="user_detail">{data?.data?.permit_number !=="undefined" ? <>{data?.data?.permit_number}</>:<>-</>}</p>
                          </Col>
                          <Col>
                            <p className="user_detailField">Business Logo:</p>
                            <p className="user_detail">{data?.data?.business_logo ? <img src={config.imageUrl + data?.data?.business_logo} alt="image" height={80} width={80} className="rounded"/>:<>-</>}</p>
                          </Col>
                          <Col>
                            <p className="user_detailField">Business Address:</p>
                            <p className="user_detail">{data?.data?.business_address ? <>{data?.data?.business_address}</>:<>-</>}</p>

                          </Col>
                        </Row>
                      </>
                      :
                      <div className="no-dat-found-box">
                        <img
                          src={
                            require("../../assets/images/no-data.svg")
                              .default
                          }
                          alt="da"
                        />
                        <h3 className="text-center text-light">
                          No data found
                        </h3>
                      </div>}
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}