import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import userImg from "../../assets/images/byDefaultUser.png";
import { OverLayAddressTooltip } from "../../common/Functions";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { pineappleDexService } from "../../service/api.service";
import swal from "sweetalert";
import { ShimmerThumbnail } from "react-shimmer-effects";


export const TransactionDetail = () => {
    const navigate = useNavigate();
    let params = useParams();
    let swapId = params && params.id ? params.id : ''

    const [loader, setLoader] = useState(false);

    const [transactionDetail, setTransactionDetail] = useState(null);
    const [copiedUserAddress, setCopiedUserAddress] = useState(false);
    const [copiedValue, setCopiedValue] = useState(null);
    const [copiedFrom, setCopiedFrom] = useState(false)
    const [copiedTo, setCopiedTo] = useState(false)
    const [copiedHash, setCopiedHash] = useState(false);


    useEffect(() => {
        // setLoader(true);
        // getTransactionDetail();
    }, []);


    const getTransactionDetail = async () => {
        try {
            let param = {
                transactionId: swapId ? swapId : ''
            }
            const response = await pineappleDexService.getSwapDetail(param);
            if (response?.status == 200) {
                let data = response.data && response.data.data ? response.data.data : null;
                setTransactionDetail(data);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                    }
                );
            }
        }
    };


    const handleCopy = (address, type) => {
        switch (type) {
            case "userAddress":
                setCopiedUserAddress(true);
                break;
            case "fromAddress":
                setCopiedFrom(true);
                break;
            case "toAddress":
                setCopiedTo(true);
                break;
            case "hashAddress":
                setCopiedHash(true);
                break;
            default:
                break;
        }
        setCopiedValue(address);
        setTimeout(() => {
            setCopiedUserAddress(false);
            setCopiedFrom(false);
            setCopiedTo(false);
            setCopiedHash(false);
        }, 2000);
    };


    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12}>
                                <Row className="justify-content-between" >
                                    <Col md={12} className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="back-btn-main me-4" onClick={() => navigate("/swap-volume", { state: "from_swap_detail_screen" })}>
                                                <i className="fa-solid fa-arrow-left me-2"></i>
                                                Back
                                            </div>
                                            <h2 className="align-self-center m-0 inner-details-heading">Transaction Detail</h2>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12}>
                                <div className="transaction-swap-details">
                                    <div className="wallet-address-body">
                                        {
                                            loader ?
                                                (
                                                    <>
                                                        {[...Array(4)].map((_, index) => (
                                                            <div className="wallet-info" key={index}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <ShimmerThumbnail />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <ShimmerThumbnail />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )
                                                :
                                                <>
                                                
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Order ID</label>
                                                                <p className="toekn-main-paragraph">{transactionDetail?.fromAmount ? transactionDetail.fromAmount : "N/A"} {transactionDetail?.usdAmount ? `($${transactionDetail.usdAmount})` : ''}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>User Wallet Address</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{'N/A'}</span>
                                                                    {
                                                                        transactionDetail && transactionDetail.userId && transactionDetail.userId.walletAddress &&
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard
                                                                                text={transactionDetail.userId.walletAddress}
                                                                                onCopy={() => handleCopy(transactionDetail.userId.walletAddress, "userAddress")}
                                                                            >
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy color="white" />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                    }
                                                                    {
                                                                        transactionDetail && copiedUserAddress &&
                                                                        (copiedValue === transactionDetail?.userId?.walletAddress) &&
                                                                        (<span style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Order Type</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{transactionDetail?.transactionHash ? transactionDetail.transactionHash : 'N/A'}</span>
                                                                    {
                                                                        transactionDetail?.transactionHash &&
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard
                                                                                text={transactionDetail.transactionHash}
                                                                                onCopy={() => handleCopy(transactionDetail.transactionHash, "hashAddress")}
                                                                            >
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy color="white" />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                    }
                                                                    {copiedHash && (copiedValue === transactionDetail?.transactionHash) && <span style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Amount</label>
                                                                <p className="toekn-main-paragraph">{transactionDetail?.transaction_fee || transactionDetail?.transaction_fee === 0 ? transactionDetail.transaction_fee : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>From Token</label>
                                                                <p className="toekn-main-paragraph d-flex align-items-center">
                                                                    {transactionDetail?.fromToken?.logoURI ? <img src={transactionDetail?.fromToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />}
                                                                    <div>
                                                                        <span>{transactionDetail?.fromToken?.symbol || ""}</span>
                                                                        <span className="toekn-main-paragraph">{transactionDetail && transactionDetail.fromToken && transactionDetail.fromToken.address ? transactionDetail.fromToken.address : 'N/A'}</span>
                                                                        {
                                                                            transactionDetail && transactionDetail.fromToken && transactionDetail.fromToken.address &&
                                                                            <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                <CopyToClipboard
                                                                                    text={transactionDetail.fromToken.address}
                                                                                    onCopy={() => handleCopy(transactionDetail.fromToken.address, "fromAddress")}
                                                                                >
                                                                                    <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                        <FaCopy color="white" />
                                                                                    </button>
                                                                                </CopyToClipboard>
                                                                            </OverLayAddressTooltip>
                                                                        }
                                                                        {copiedFrom && (copiedValue === transactionDetail?.fromToken?.address) && <span style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>From Amount</label>
                                                                <p className="toekn-main-paragraph">{transactionDetail?.fromAmount ? transactionDetail.fromAmount : "N/A"} {transactionDetail?.usdAmount ? `($${transactionDetail.usdAmount})` : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>To Token</label>
                                                                <p className="toekn-main-paragraph d-flex align-items-center">
                                                                    {transactionDetail?.toToken?.logoURI ? <img src={transactionDetail?.toToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />}
                                                                    <div>
                                                                        <span>{transactionDetail?.toToken?.symbol || ""}</span>
                                                                        <span className="toekn-main-paragraph">{transactionDetail?.toToken && transactionDetail.toToken?.address ? transactionDetail.toToken.address : 'N/A'}</span>
                                                                        {
                                                                            transactionDetail?.toToken && transactionDetail.toToken.address &&
                                                                            <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                <CopyToClipboard
                                                                                    text={transactionDetail.toToken.address}
                                                                                    onCopy={() => handleCopy(transactionDetail.toToken.address, "toAddress")}
                                                                                >
                                                                                    <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                        <FaCopy color="white" />
                                                                                    </button>
                                                                                </CopyToClipboard>
                                                                            </OverLayAddressTooltip>
                                                                        }
                                                                        {copiedTo && (copiedValue === transactionDetail?.toToken?.address) && <span style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>To Amount</label>
                                                                <p className="toekn-main-paragraph">{transactionDetail?.toAmount ? transactionDetail.toAmount : 'N/A'} {transactionDetail?.toUsdAmount ? `($${transactionDetail.toUsdAmount})` : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Transaction Hash</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{transactionDetail?.transactionHash ? transactionDetail.transactionHash : 'N/A'}</span>
                                                                    {
                                                                        transactionDetail?.transactionHash &&
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard
                                                                                text={transactionDetail.transactionHash}
                                                                                onCopy={() => handleCopy(transactionDetail.transactionHash, "hashAddress")}
                                                                            >
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy color="white" />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                    }
                                                                    {copiedHash && (copiedValue === transactionDetail?.transactionHash) && <span style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Transaction Fee {`(ETH)`}</label>
                                                                <p className="toekn-main-paragraph">{transactionDetail?.transaction_fee || transactionDetail?.transaction_fee === 0 ? transactionDetail.transaction_fee : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Date & Time</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{transactionDetail?.transactionHash ? transactionDetail.transactionHash : 'N/A'}</span>
                                                                    {
                                                                        transactionDetail?.transactionHash &&
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard
                                                                                text={transactionDetail.transactionHash}
                                                                                onCopy={() => handleCopy(transactionDetail.transactionHash, "hashAddress")}
                                                                            >
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy color="white" />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                    }
                                                                    {copiedHash && (copiedValue === transactionDetail?.transactionHash) && <span style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Order Status</label>
                                                                <p className="toekn-main-paragraph">{transactionDetail?.transaction_fee || transactionDetail?.transaction_fee === 0 ? transactionDetail.transaction_fee : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Amendment Detail</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{transactionDetail?.transactionHash ? transactionDetail.transactionHash : 'N/A'}</span>
                                                                    {
                                                                        transactionDetail?.transactionHash &&
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard
                                                                                text={transactionDetail.transactionHash}
                                                                                onCopy={() => handleCopy(transactionDetail.transactionHash, "hashAddress")}
                                                                            >
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy color="white" />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                    }
                                                                    {copiedHash && (copiedValue === transactionDetail?.transactionHash) && <span style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Cancellation Detail</label>
                                                                <p className="toekn-main-paragraph">{transactionDetail?.transaction_fee || transactionDetail?.transaction_fee === 0 ? transactionDetail.transaction_fee : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Order Expiry</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{transactionDetail?.transactionHash ? transactionDetail.transactionHash : 'N/A'}</span>
                                                                    {
                                                                        transactionDetail?.transactionHash &&
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard
                                                                                text={transactionDetail.transactionHash}
                                                                                onCopy={() => handleCopy(transactionDetail.transactionHash, "hashAddress")}
                                                                            >
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy color="white" />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                    }
                                                                    {copiedHash && (copiedValue === transactionDetail?.transactionHash) && <span style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-6">
                                                                <label>Cancellation Detail</label>
                                                                <p className="toekn-main-paragraph">{transactionDetail?.transaction_fee || transactionDetail?.transaction_fee === 0 ? transactionDetail.transaction_fee : 'N/A'}</p>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};
