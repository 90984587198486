import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";

const ConfigurationModal = ({ show, handleClose, configData, isEdit, handleSave }) => {
    const [formData, setFormData] = useState({
        eventType: configData?.eventType || "",
        priority: configData?.priority || "HIGH",
        range: configData?.range || "",
        time: configData?.time || "",
        timeType: configData?.timeType || "",
    });
    useEffect(() => {
        setFormData({
            eventType: configData?.eventType || "",
            priority: configData?.priority || "HIGH",
            range: configData?.range || "",
            time: configData?.time || "",
            timeType: configData?.timeType || "",
        });
    }, [configData]);

    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    const validateForm = () => {
        let valid = true;
        let newErrors = {};

        if (!formData.eventType) {
            newErrors.eventType = true;
            valid = false;
        }

        if (!formData.priority) {
            newErrors.priority = true;
            valid = false;
        }

        if (!formData.range) {
            newErrors.range = true;
            valid = false;
        }

        if (!formData.time) {
            newErrors.time = true;
            valid = false;
        }

        // if (!formData.timeType) {
        //     newErrors.timeType = true;
        //     valid = false;
        // }

        setErrors(newErrors);
        setIsFormValid(valid);
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    //     if (value) {
    //         setErrors((prevErrors) => ({
    //             ...prevErrors,
    //             [name]: false,
    //         }));
    //     }
    // };
    const handleChange = (e) => {
        const { name, value } = e.target;
        let isValid = true;
        // Apply numeric validation for all fields except 'priority'
        if (name !== 'priority') {
            isValid = /^[0-9]*$/.test(value);
        }
    
        // If valid, update the formData
        if (isValid) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    
        // Reset error if the input is valid
        if (isValid && value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: false,
            }));
        }
    };
    
    

    const handleSubmit = () => {
        validateForm();

        if (isFormValid) {
            handleSave(formData);
            handleClose();
        }
    };

    const getInputClass = (field) => {
        return errors[field] ? "is-invalid" : "";
    };

    return (
        <Modal show={show} onHide={handleClose} className="add-new-device-popup" centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? "Edit Configuration" : "Add Configuration"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="eventType">
                        <Form.Label>Event Type</Form.Label>
                        <Form.Control
                            type="text"
                            name="eventType"
                            value={formData.eventType}
                            onChange={handleChange}
                            onBlur={validateForm}
                            className={getInputClass("eventType")}
                            readOnly={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="priority" className="mt-3 position-relative">
                        <Form.Label>Priority</Form.Label>
                        <Form.Control
                            as="select"
                            name="priority"
                            value={formData.priority}
                            onChange={handleChange}
                            onBlur={validateForm}
                            className={getInputClass("priority")}
                        >
                            <option value="">Select Priority</option>
                            <option value="HIGH">HIGH</option>
                            <option value="MEDIUM">MEDIUM</option>
                            <option value="LOW">LOW</option>
                        </Form.Control>
                        <i className="fa-solid fa-chevron-down"></i>
                    </Form.Group>
                    <Form.Group controlId="range" className="mt-3">
                        <Form.Label>
                            <>
                           {formData?.eventType =="API_KEY_USAGE"? "API Usage Range": formData?.eventType === "TRANSACTIONS_FAILURES" ? "Transaction Failed Range" :"User Range" } 
                           {formData?.eventType !=="API_KEY_USAGE" ?  
                           formData?.eventType === "HIGH_TRADING" || formData?.eventType === "TRANSACTIONS_FAILURES" ? "(%)" : "":
                           ""}
                           </>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="range"
                            value={formData.range}
                            onChange={handleChange}
                            onBlur={validateForm}
                            className={getInputClass("range")}
                        />
                    </Form.Group>
                    <Form.Group controlId="time" className="mt-3 position-relative">
                        <Form.Label>Time in {formData?.timeType?.toLowerCase()}</Form.Label>
                        <Form.Control
                            type="text"
                            name="time"
                            value={formData.time}
                            onChange={handleChange}
                            onBlur={validateForm}
                            className={getInputClass("time")}
                        />
                        <i className="fa-regular fa-clock"></i>
                    </Form.Group>
                    {/* <Form.Group controlId="timeType" className="mt-3">
                        <Form.Label>Time Type</Form.Label>
                        <Form.Control
                            type="text"
                            name="timeType"
                            value={formData.timeType}
                            onChange={handleChange}
                            onBlur={validateForm}
                            className={getInputClass("timeType")}
                            readOnly={true}
                        />
                    </Form.Group> */}
                </Form>
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button
                    variant=""
                    className="text-dark"
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                >
                    {isEdit ? "Save" : "Add"}
                </Button>
                <Button variant="" className="text-dark edit-close" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfigurationModal;
