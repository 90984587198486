import { useEffect, useState } from "react";
import { Login } from "./components/login/Login";
import { Home } from "./components/dashboard/Home";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./util/protectedRoute";
import { getItem } from "./util/localStorage";
import { useNavigate } from "react-router-dom";
import { PageNotFound } from "./components/Notfound.js";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import { useLocation } from "react-router";
import Footer from "./common/Footer";
// CSS
import "./App.css";
import "./assets/css/style.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'
import { MyContext } from "./config/config";
import { UserInfo } from "./components/userManagement/UserProfileDetails.js";
import { ChangePassword } from "./components/changePassword/ChangePassword";
import { SwapVolume } from "./components/swapVolume/SwapValume.js";
import { Report } from "./components/report/Report.js";
import { Reveneu } from "./components/revenu/Revenu.js";
import { SwapPerToken } from "./components/swapperToken/SwapToken.js";
import { TokenTransactions } from "./components/tokenTransactions/tokenTransaction.js";
import { TransactionsList } from "./components/transactions/transactionsList.js";
import { UserActivities } from "./components/userManagement/userActivities.js";
import { UsersList } from './components/userManagement/UsersList.js'
import { ContactUsListing } from "./components/contactManagement/contactUsList.js";
import { PopularPairList } from "./components/popularPair/PopularPairList.js";
import { TransactionDetail } from "./components/transactions/transactionsDetail.js";
import { OrderDetail } from "./components/limitOrder/orderDetail.js";
import { LimitOrdersList } from "./components/limitOrder/limitOrdersListing.js";
import { SwapDetail } from "./components/swapVolume/swapDetail.js";
import { TokenManagement } from "./components/tokenManagement/TokenManagement.js";
import { TokenDetail } from "./components/tokenManagement/TokenDetail.js";
import  NotificationSecurity  from "./components/notificationSecurity/NotificationSecurity.js";
import Configuration from "./components/configuration/Configuration.js";
import { NotificationDetail } from "./components/notificationSecurity/NotificationSecurityDetail.js";
import { GlobalState } from "./globalState/GlobalState.js";
import { GlobalContext } from "./globalState/GlobalContext.js";
import { useContext } from "react";
import useNotificationSetup from "./config/firebase.js";
import useVisibilityChange from "./components/firebaseNotification/useVisibilityChange.js";
import { ToastContainer } from "react-toastify";
 


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const globalStates = useContext(GlobalContext);
  const [affiliateRequest, setAffiliateRequest] = useState(false);
  useNotificationSetup();
  const isForeground = useVisibilityChange();
  useEffect(() => {
    if (getItem("accessToken")) {
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data && event.data.type === 'NEW_NOTIFICATION') {
          globalStates.setManagenotification(!globalStates.managenotification);
        }
      });
    }
  }, []);

  return (
   
    <MyContext.Provider value={{ affiliateRequest, setAffiliateRequest }}>
      <>
      <ToastContainer />
        {location.pathname !== "/" ? (
          <>
            <Header />
            <Sidebar />
          </>
        ) : (
          <></>
        )}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-List"
            element={
              <ProtectedRoute>
                <UsersList />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/UserInfo/:id"
            element={
              <ProtectedRoute>
                <UserInfo />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/change-password"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route path="/swap-volume"
            element={
              <ProtectedRoute>
                <SwapVolume />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="/report"
            element={
              <ProtectedRoute>
                <Report />
              </ProtectedRoute>
            }
          ></Route>

          <Route path="/revenue"
            element={
              <ProtectedRoute>
                <Reveneu />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="/swap"
            element={
              <ProtectedRoute>
                <SwapPerToken />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/token-transactions/:id"
            element={
              <ProtectedRoute>
                <TokenTransactions />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/swap-detail/:id"
            element={
              <ProtectedRoute>
                <SwapDetail />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/transactions"
            element={
              <ProtectedRoute>
                <TransactionsList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/user-activities/:id"
            element={
              <ProtectedRoute>
                <UserActivities />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/contact-us"
            element={
              <ProtectedRoute>
                <ContactUsListing />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/popular-pair"
            element={
              <ProtectedRoute>
                <PopularPairList />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/transaction-detail"
            element={
              <ProtectedRoute>
                <TransactionDetail />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/limit-orders"
            element={
              <ProtectedRoute>
                <LimitOrdersList />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/limit-order-detail"
            element={
              <ProtectedRoute>
                <OrderDetail />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/token-management"
            element={
              <ProtectedRoute>
                <TokenManagement />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
              path="/notification-security"
              element={
                <ProtectedRoute>
                  <NotificationSecurity />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/notification-security-detail/:id"
              element={
                <ProtectedRoute>
                  <NotificationDetail />
                </ProtectedRoute>
              }
            ></Route>

                  <Route
              path="/configuration"
              element={
                <ProtectedRoute>
                  <Configuration />
                </ProtectedRoute>
              }
            ></Route>
          <Route
            path="/token/:id"
            element={
              <ProtectedRoute>
                <TokenDetail />
              </ProtectedRoute>
            }
          >
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname !== "/" ? <Footer /> : ""}
      </>
    </MyContext.Provider>
  );
}

export default App;
