import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Swal from "sweetalert2";
import copy from "copy-to-clipboard";
import { pineappleDexService } from "../../service/api.service";
import userImg from "../../assets/images/byDefaultUser.png"
import Switch from "react-switch";
export const Report = () => {
  const [userData, setUserData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1)
  const [copyedStripeId, setCopyedStripeId] = useState(false)
  const [type, setType] = useState("")
  const copyToClipboard = (stripeId) => {
    copy(stripeId);
    setCopyedStripeId(true);
    setTimeout(() => {
      setCopyedStripeId(false);
    }, 1000);
  };

  useEffect(() => {
    getTransactionHistory(page, search)
  }, [])

  async function getTransactionHistory(page, search) {
    setLoader(true)
    try {
      const params = {
        page: page,
        search: search
      }
      const response = await pineappleDexService.transaction_history(params)
      if (response?.status == 200) {
        setUserData(response?.data?.data)
        setTotalItems(response?.data?.total_count)
        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  /* pagination */
  function handlePageChange(pageNumber) {
    setPage(pageNumber);
    getTransactionHistory(pageNumber, search)
  }

  /* search */
  function handleUpdateSearch(search) {
    setSearch(search);
    setPage(1);
    getTransactionHistory(page, search)
  }
  /* user disable api */

  async function disableUser(userdata) {
    try {
      const params = {
        userId: userdata.id,
      }
      const response = await pineappleDexService.userStatus(params)
      if (response.status === 200) {
        getTransactionHistory(page, search)
      }
    }
    catch (error) {
      console.log("Error", error)
      setLoader(false)
      swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
    }


  }

  return (
    <>
      <div className="dashboard-main-area">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12}>
                <Row className="justify-content-between">
                  <Col md={4} className="d-flex ">
                    <h2 className="align-self-center m-0">Transaction History</h2>
                  </Col>

                  <Col md={4} className="search-area">
                  
                    <form
                      autoComplete="off"
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                       <div className="position-relative">
                      <input
                        type="text"
                        className="form-control inner-input"
                        placeholder="Search here..."
                        onChange={(e) => handleUpdateSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        onClick={() => getTransactionHistory(page, search)}
                      ></i>
                      <i className="fa fa-close d-none" aria-hidden="true"></i>
                      </div>
                    </form>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Wallet Address</th>
                        <th>Email</th>
                        <th>Last Active Session</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData && userData?.length > 0 ? (
                        userData?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{(page - 1) * 10 + (index + 1)}</td>
                              <td>{data?.profile_image ? <img src={data?.profile_image} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} </td>
                              <td>{data?.name ? <>{data?.name}{" "}</> : <>{"N/A"}</>}</td>
                              <td>{data?.walllet_Address || "N/A"}</td>
                              <td>{data?.email ? <>{data?.email}</> : <>{"N/A"}</>}</td>
                              <td>{data?.islast_session || "N/A" }</td>
                              <td>
                                <Switch
                                  onChange={() => {
                                    const action = data.is_active ? 'disable' : 'enable';
                                    const buttonLabel = data.is_active ? 'Yes, disable' : 'Yes, enable';
                                    swal({
                                      title: 'Are you sure?',
                                      text: `You want to ${action} the user?`,
                                      icon: 'warning',
                                      buttons: ['Cancel', buttonLabel],
                                      dangerMode: true,
                                    }).then((confirmed) => {
                                      if (confirmed) {
                                        disableUser(data)
                                      }
                                    })
                                  }}
                                  checked={data.is_active}
                                />
                              </td>
                            </tr>
                          )
                        })) : (
                        <tr className="no-dat-found-box">
                          <td colSpan={10}>
                            <img
                              src={
                                require("../../assets/images/no-data.svg")
                                  .default
                              }
                              alt="da"
                            />
                            <h3 className="text-center text-light">
                              No Transaction History found
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {userData.length > 0 && totalItems > 10 ?
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                    : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
