import React, { useState, useEffect } from "react";
import {
    Col,
    Container,
    Row,
    Table,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { pineappleDexService } from "../../service/api.service";
import { useParams } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import userImg from "../../assets/images/byDefaultUser.png";
import { OverLayAddressTooltip } from "../../common/Functions";


export const TokenTransactions = () => {
    let params = useParams();
    let tokenAddress = params && params.id ? params.id : ''

    const [transactionList, setTransactionList] = useState([]);
    const [totalItems, setTotalItems] = useState();
    const [loader, setLoader] = useState(false);
    const [copiedFromAddress, setCopiedFromAddress] = useState(false);
    const [copiedToAddress, setCopiedToAddress] = useState(false);
    const [copiedUserId, setCopiedUserId] = useState(false);
    const [copiedValue, setCopiedValue] = useState(null);

    const [page, setPage] = useState(0);
    const [activePage, setActivePage] = useState(1);


    useEffect(() => {
        setLoader(true);
        getTokenTransactions(page);
    }, []);


    const getTokenTransactions = async (pages) => {
        try {
            let param = {
                page: pages ? pages : 0,
                token: tokenAddress
            }
            const response = await pineappleDexService.individualTokenTransactions(param);
            if (response?.status == 200) {
                let totalCount = response.data && response.data.totalCount ? response.data.totalCount : 0;
                let list = response.data && response.data.data ? response.data.data : [];
                setTransactionList(list);
                setTotalItems(totalCount);
                setLoader(false);
            }
        }
        catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                    }
                );
            }
        }
    };


    function handlePageChange(pageNumber) {
        let page = pageNumber - 1
        setPage(page);
        setActivePage(pageNumber);
        setLoader(true);
        getTokenTransactions(page);
    };


    const handleCopy = (address, type) => {
        if (type === "userId") {
            setCopiedUserId(true);
            setCopiedValue(address);
            setTimeout(() => setCopiedUserId(false), 2000);
        }
        else if (type === 'fromAddress') {
            setCopiedFromAddress(true);
            setCopiedValue(address);
            setTimeout(() => setCopiedFromAddress(false), 2000); // Reset copied state after 2 seconds
        }
        else if (type === 'toAddress') {
            setCopiedToAddress(true);
            setCopiedValue(address);
            setTimeout(() => setCopiedToAddress(false), 2000);
        }
        else;
    };


    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12}>
                                <Row className="justify-content-between" >
                                    <Col md={12} className="d-flex ">
                                        <h2 className="align-self-center m-0">Token Transactions List </h2><span style={{ color: 'white', marginLeft: 15 }}>{`(${tokenAddress})`}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>User ID</th>
                                                <th>From Type</th>
                                                <th>From Address</th>
                                                <th>From Amount</th>
                                                <th>To Type</th>
                                                <th>To Address</th>
                                                <th>To Amount</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactionList && transactionList?.length > 0 ? (
                                                transactionList?.map((data, index) => {
                                                    const formattedUserId = data?.userId
                                                        ? `${data.userId.slice(0, 5)}...${data.userId.slice(-5)}`
                                                        : 'N/A';
                                                    const fullUserId = data.userId ? data?.userId : 'N/A'
                                                    const formattedFromAddress = data?.fromToken?.address
                                                        ? `${data.fromToken.address.slice(0, 5)}...${data.fromToken.address.slice(-5)}`
                                                        : 'N/A';
                                                    const fromTokenFullAddress = data.fromToken && data.fromToken.address ? data.fromToken.address : 'N/A'
                                                    const toTokenFullAddress = data.toToken && data.toToken.address ? data.toToken.address : 'N/A'
                                                    const formattedToAddress = data?.toToken?.address
                                                        ? `${data.toToken.address.slice(0, 5)}...${data.toToken.address.slice(-5)}`
                                                        : 'N/A';

                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <OverLayAddressTooltip overlayValue={fullUserId}>
                                                                    <span>{formattedUserId} </span>
                                                                </OverLayAddressTooltip>
                                                                {formattedUserId && (
                                                                    <>
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard text={fullUserId} onCopy={() => handleCopy(fullUserId, "userId")}>
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                        {(copiedValue === fullUserId) && copiedUserId ? <span style={{ marginLeft: '8px', color: 'green' }}>Copied!</span> : null}
                                                                    </>
                                                                )}

                                                            </td>
                                                            <td>{data?.fromToken?.logoURI ? <img src={data?.fromToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />}{data?.fromToken?.symbol || "N/A"}</td>
                                                            <td>
                                                                <OverLayAddressTooltip overlayValue={fromTokenFullAddress}>
                                                                    <span>
                                                                        {formattedFromAddress}
                                                                    </span>
                                                                </OverLayAddressTooltip>
                                                                {formattedFromAddress && (
                                                                    <>
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard text={fromTokenFullAddress} onCopy={() => handleCopy(fromTokenFullAddress, "fromAddress")}>
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                        {(copiedValue === fromTokenFullAddress) && copiedFromAddress ? <span style={{ marginLeft: '8px', color: 'green' }}>Copied!</span> : null}
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>{data?.fromAmount || "-"}</td>
                                                            <td>{data?.toToken?.logoURI ? <img src={data?.toToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />}{data?.toToken?.symbol || "N/A"}</td>
                                                            <td>
                                                                <OverLayAddressTooltip overlayValue={toTokenFullAddress}>
                                                                    <span>
                                                                        {formattedToAddress}
                                                                    </span>
                                                                </OverLayAddressTooltip>
                                                                {formattedToAddress && (
                                                                    <>
                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                            <CopyToClipboard text={toTokenFullAddress} onCopy={() => handleCopy(toTokenFullAddress, "toAddress")}>
                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                    <FaCopy />
                                                                                </button>
                                                                            </CopyToClipboard>
                                                                        </OverLayAddressTooltip>
                                                                        {(copiedValue === toTokenFullAddress) && copiedToAddress ? <span style={{ marginLeft: '8px', color: 'green' }}>Copied!</span> : null}
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>{data?.toAmount || "-"}</td>
                                                            <td>{"N/A"}</td>
                                                        </tr>
                                                    )
                                                })) : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={require("../../assets/images/no-data.svg").default}
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    {transactionList.length > 0 && totalItems > 10 ?
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalItems}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};
