import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { pineappleDexService } from "../../service/api.service";
import { useCallback, useEffect, useState } from "react";
import swal from "sweetalert";
import { debounce } from "lodash";
import Pagination from "react-js-pagination";
import { ShimmerThumbnail } from "react-shimmer-effects";



export const PopularPairList = () => {
    const [popularPairList, setPopularPairList] = useState([]);
    const [totalItems, setTotalItems] = useState();
    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [loader, setLoader] = useState(false);

    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);

    const [totlaValueSorting, setTotalValueSorting] = useState(null);
    const [totalTransactionsSorting, setTotalTransactionsSorting] = useState(null);


    useEffect(() => {
        setLoader(true);
        getPopularPairsList(0);
    }, []);


    const getPopularPairsList = async (pages, search, filterVal, sortingOn, sortType) => {
        try {
            let param = {
                page: pages ? pages : 0,
                keyword: search ? search : '',
                filter: filterVal ? filterVal : '',
                sort: sortingOn ? sortingOn : '',
                sortType: sortType ? sortType : '',
            }
            const response = await pineappleDexService.getPopularPairsList(param);
            if (response?.status == 200) {
                let totalCount = response.data && response.data.totalCount ? response.data.totalCount : 0;
                let list = response.data && response.data.data ? response.data.data : [];
                setPopularPairList(list);
                setTotalItems(totalCount);
                setLoader(false);
                setSearchLoader(false);
            }
        }
        catch (error) {
            setLoader(false);
            setSearchLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    };


    function handlePageChange(pageNumber) {
        let page = pageNumber - 1
        setPage(page);
        setActivePage(pageNumber);
        setLoader(true);
        getPopularPairsList(page, search, filter);
    };


    const handleSorting = (sortOn) => {
        if (sortOn === "totalValue") {
            let sorttype = totlaValueSorting && totlaValueSorting === "asc" ? "desc" : "asc"
            setTotalValueSorting(sorttype);
            setTotalTransactionsSorting('');
            getPopularPairsList(page, search, filter, "tv", sorttype);
        }
        else if (sortOn === "totalTransactions") {
            let sorttype = totalTransactionsSorting && totalTransactionsSorting === "asc" ? "desc" : "asc"
            setTotalValueSorting('');
            setTotalTransactionsSorting(sorttype);
            getPopularPairsList(page, search, filter, "tt", sorttype);
        }
        else;
    };


    const handleFilters = async (value) => {
        setFilter(value);
        setTotalValueSorting('');
        setTotalTransactionsSorting('');
        setLoader(true);
        getPopularPairsList(page, search, value);
    };


    const searchData = useCallback(debounce(getPopularPairsList, 1000), []);

    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-2">
                            <Col md={12}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex ">
                                        <h2 className="align-self-center m-0">Popular Pair</h2>
                                    </div>
                                    <div className="search-area main-search-area">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <form
                                            >
                                                <div className="position-relative width-inner-main">
                                                    <input
                                                        type="text"
                                                        value={search}
                                                        className="form-control inner-input"
                                                        placeholder="Search by token..."
                                                        onChange={(e) =>
                                                        (
                                                            setSearchLoader(true),
                                                            searchData(page, e.target.value, filter),
                                                            setSearch(e.target.value),
                                                            setTotalValueSorting(''),
                                                            setTotalTransactionsSorting('')
                                                        )
                                                        }
                                                        onKeyDown={(e) => {
                                                            if (!e.target.value && e.code === "Space") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    {
                                                        searchLoader ?
                                                            <span class="loader-search"></span>
                                                            :
                                                            <i
                                                                className="fa fa-search"
                                                                aria-hidden="true"
                                                            ></i>
                                                    }
                                                    <i className="fa fa-close d-none" aria-hidden="true"></i>
                                                </div>
                                            </form>
                                            <div className="select-days position-relative">
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    value={filter}
                                                    onChange={(e) => handleFilters(e.target.value)}
                                                >
                                                    <option value="">All</option>
                                                    <option value="24h">Last 24 Hours</option>
                                                    <option value="48h">Last 48 Hours</option>
                                                    <option value="7d">Last 7 Days</option>
                                                    <option value="15d">Last 15 Days</option>
                                                    <option value="30d">Last 30 Days</option>
                                                </Form.Select>
                                                <i className="fa-solid fa-chevron-down"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12}>
                                <div className="audit-request-box under-without-pagination">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Pairs</th>
                                                <th>
                                                    <p className="d-flex align-items-center">
                                                        <span className="cursor-pointer" onClick={() => handleSorting("totalValue")}>Total Volume</span>
                                                        <span className="span-sort ms-2">
                                                            {
                                                                totlaValueSorting === "desc" ?
                                                                    <i onClick={() => handleSorting("totalValue")} className="fa-solid fa-sort-up cursor-pointer"></i>
                                                                    :
                                                                    totlaValueSorting === "asc" ?
                                                                        <i onClick={() => handleSorting("totalValue")} className="fa-solid fa-sort-down cursor-pointer"></i>
                                                                        :
                                                                        <>
                                                                            <i onClick={() => handleSorting("totalValue")} className="fa-solid fa-sort-up cursor-pointer"></i>
                                                                            <i onClick={() => handleSorting("totalValue")} className="fa-solid fa-sort-down cursor-pointer"></i>
                                                                        </>
                                                            }
                                                        </span>
                                                    </p>
                                                </th>
                                                <th>
                                                    <p className="d-flex align-items-center">
                                                        <span className="cursor-pointer" onClick={() => handleSorting("totalTransactions")} >Total Transaction</span>
                                                        <span className="span-sort ms-2">
                                                            {
                                                                totalTransactionsSorting === "desc" ?
                                                                    <i onClick={() => handleSorting("totalTransactions")} className="fa-solid fa-sort-up cursor-pointer"></i>
                                                                    :
                                                                    totalTransactionsSorting === "asc" ?
                                                                        <i onClick={() => handleSorting("totalTransactions")} className="fa-solid fa-sort-down cursor-pointer"></i>
                                                                        :
                                                                        <>
                                                                            <i onClick={() => handleSorting("totalTransactions")} className="fa-solid fa-sort-up cursor-pointer"></i>
                                                                            <i onClick={() => handleSorting("totalTransactions")} className="fa-solid fa-sort-down cursor-pointer"></i>
                                                                        </>
                                                            }
                                                        </span>
                                                    </p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loader ?
                                                    (
                                                        <>
                                                            {[...Array(10)].map((_, index) => (
                                                                <tr key={index}>
                                                                    <td><ShimmerThumbnail /></td>
                                                                    <td><ShimmerThumbnail /></td>
                                                                    <td><ShimmerThumbnail /></td>
                                                                    <td><ShimmerThumbnail /></td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )
                                                    :
                                                    popularPairList && popularPairList.length > 0 ?
                                                        popularPairList.map((item, index) => {
                                                            const serialNumber = (activePage - 1) * 10 + index + 1;
                                                            let fromTokenData = item.fromToken ? item.fromToken : ''
                                                            let toTokenData = item.toToken ? item.toToken : ''

                                                            return (
                                                                <tr key={index}>
                                                                    <td>{serialNumber}</td>
                                                                    <td>
                                                                        <div className="swap-area-text">
                                                                            {(fromTokenData && fromTokenData.symbol) && (toTokenData && toTokenData.symbol) ? <p>{fromTokenData.symbol} <i className="fa-solid fa-arrow-right-arrow-left mx-2"></i> {toTokenData.symbol} </p> : "N/A"}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {item.totalVolume ? item.totalVolume : 'N/A'}
                                                                    </td>
                                                                    <td>
                                                                        {item.totalSwap ? item.totalSwap : 'N/A'}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        :
                                                        <tr className="no-dat-found-box">
                                                            <td colSpan={10}>
                                                                <img
                                                                    src={
                                                                        require("../../assets/images/no-data.svg")
                                                                            .default
                                                                    }
                                                                    alt="no-data-image"
                                                                />
                                                                <h3 className="text-center text-light">
                                                                    No data found
                                                                </h3>
                                                            </td>
                                                        </tr>
                                            }

                                        </tbody>
                                    </Table>
                                    {popularPairList.length > 0 && totalItems > 10 && loader ?
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalItems}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};
