import Select, { components } from 'react-select';


export const ReactSelectSearchableDropdown = (props) => {
    const { placeholder, value, onChange, list } = props

    const customStyles = {
        // control: (provided, state) => ({
        //     ...provided,
        //     backgroundColor: 'black',  // Adjust this to your preferred background color
        //     // Other styles as needed
        // }),
        // menuPortal: (provided) => ({
        //     ...provided,
        //     zIndex: 9999, // Ensure it appears above other elements
        // }),
        // menu: (provided) => ({
        //     ...provided,
        //     backgroundColor: 'black', // Adjust background color as needed
        // }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'black' : 'black', // Example: Adjust background color of selected item
            // color: state.isSelected ? 'white' : 'black', // Example: Adjust text color of selected item
        }),
    };


    const CustomSelectOptions = (props) => {
        const { Option } = components

        return (
            <Option {...props.items}>
                {props && props.items && props.items.data && props.items.data.label ?
                    <div style={props && props.value === (props.items && props.items.data && props.items.data.address) ? { background: '#ffffff1a' } : {}}>
                        {props.items.data.label}
                    </div>
                    : ''}
                {/* {
                    props && props.value === (props.items && props.items.data && props.items.data.address) ?
                        <i className="fa-solid fa-check text-light"></i>
                        :
                        null
                } */}
            </Option>
        )
    };


    return (
        <Select
            styles={customStyles}
            placeholder={placeholder ? placeholder : 'select here...'}
            value={value}
            options={list ? list : []}
            onChange={(item) => onChange(item)}
            components={{
                Option: props => (
                    <CustomSelectOptions
                        items={props}
                        value={value && value.address}
                    />
                )
            }}
        />
    )
};