import React, { useState, useEffect } from "react";
import {
    Col,
    Container,
    Row,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { pineappleDexService } from "../../service/api.service";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { handleTransactionFee } from "../../common/Functions";



export const UserActivities = () => {
    let params = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    let transactionsUserId = params && params.id ? params.id : ''
    let userWallerAddress = state && state.userWallerAddress ? state.userWallerAddress : null


    const [userActivitiesList, setUserActivitiesList] = useState([]);
    const [totalItems, setTotalItems] = useState();
    const [loader, setLoader] = useState(false);

    const [page, setPage] = useState(0);
    const [activePage, setActivePage] = useState(1);


    useEffect(() => {
        setLoader(true);
        getUserActivities(0);
    }, []);


    const getUserActivities = async (page) => {
        try {
            let param = {
                page: page ? page : 0,
                userId: transactionsUserId
                // userId: "6683b1923406c28a37ee5006"
            }
            const response = await pineappleDexService.getUserActivities(param);
            if (response?.status == 200) {
                let totalCount = response.data && response.data.totalCount ? response.data.totalCount : 0;
                let list = response.data && response.data.data ? response.data.data : [];
                setUserActivitiesList(list);
                setTotalItems(totalCount);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false)
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                    }
                );
            }
        }
    };


    function handlePageChange(pageNumber) {
        let page = pageNumber - 1
        setPage(page);
        setActivePage(pageNumber);
        setLoader(true);
        getUserActivities(page);
    };



    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12}>
                                <Row className="justify-content-between" >
                                    <Col md={12} className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="back-btn-main me-4" onClick={() => navigate("/user-List", { state: "from_user_activities_screen" })}>
                                                <i className="fa-solid fa-arrow-left me-2"></i>
                                                Back
                                            </div>
                                            <h2 className="align-self-center m-0 inner-details-heading">User Activies</h2>
                                        </div>
                                        {userWallerAddress ? <span className="wallet-address-inneer"><i className="fa-solid fa-wallet me-1"></i> {`${userWallerAddress}`}</span> : ''}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12}>
                                <div className="scroll-data-div">
                                    {
                                        loader ?
                                            (
                                                <>
                                                    {[...Array(15)].map((_, index) => (
                                                        <div key={index}>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <ShimmerThumbnail />
                                                                </Col>
                                                                <Col md={8}>
                                                                    <ShimmerThumbnail />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ))}
                                                </>
                                            )
                                            :
                                            userActivitiesList && userActivitiesList.length > 0 ?
                                                userActivitiesList.map((item, index) => {
                                                    let fromTokenData = item.fromTokens && item.fromTokens.length > 0 ? item.fromTokens[0] : null
                                                    let toTokenData = item.toTokens && item.toTokens.length > 0 ? item.toTokens[0] : null
                                                    return (
                                                        <div className="user-activities-box scroll" key={index}>
                                                            {
                                                                item.logType === "Login" ?
                                                                    <div className="user-activities-box-underder">
                                                                        <Row>
                                                                            <Col md={4}>
                                                                                <div className="user-activities-main">
                                                                                    <label>
                                                                                        Login
                                                                                    </label>
                                                                                    <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={8}>
                                                                                <div className="user-activities-main">
                                                                                    <div>IP  :  {item.ip ? item.ip : 'N/A'}</div>
                                                                                    <div>Country  : {item.country ? item.country : 'N/A'}</div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    :
                                                                    item.logType === "SaveSwapTransaction" ?
                                                                        <div className="user-activities-box-underder">
                                                                            <Row>
                                                                                <Col md={4}>
                                                                                    <div className="user-activities-main">
                                                                                        <Link to={`/swap-detail/${item.responseObject?._id}`}>
                                                                                            <label className="cursor-pointer">
                                                                                                Swap Transation
                                                                                            </label>
                                                                                        </Link>
                                                                                        <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={8}>
                                                                                    <div className="user-activities-main">
                                                                                        {/* <div>Transaction Id  :  {item.fromTokens && item.fromTokens.transaction_fee === 0 || item.responseObject && item.responseObject.transaction_fee ? `${item.responseObject?.transaction_fee}` : 'N/A'}</div> */}
                                                                                        <div>Tokens  :  {(fromTokenData && fromTokenData.symbol) && (toTokenData && toTokenData.symbol) ? `${fromTokenData.symbol}/${toTokenData.symbol}` : 'N/A'}</div>
                                                                                        <div>Amount :  {item.responseObject && item.responseObject.fromAmount === 0 || item.responseObject && item.responseObject.fromAmount ? `${item.responseObject?.fromAmount}` : 'N/A'} {"->"} {item.responseObject && item.responseObject.toAmount === 0 || item.responseObject && item.responseObject.toAmount ? `${item.responseObject?.toAmount}` : 'N/A'} </div>
                                                                                        <div>Transaction Fee :  {item.responseObject && item.responseObject.transaction_fee === 0 || item.responseObject && item.responseObject.transaction_fee ? handleTransactionFee(item.responseObject.transaction_fee ): 'N/A'}</div>
                                                                                        {/* <div>Status :  {item.responseObject && item.responseObject.transaction_fee === 0 || item.responseObject && item.responseObject.transaction_fee ? `${item.responseObject?.transaction_fee}` : 'N/A'}</div> */}
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        :
                                                                        item.logType === "Logout" ?
                                                                            <div className="user-activities-box-underder">
                                                                                <Row>
                                                                                    <Col md={4}>
                                                                                        <div className="user-activities-main">
                                                                                            <label>
                                                                                                Logout
                                                                                            </label>
                                                                                            <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col md={8}>
                                                                                        <div className="user-activities-main">
                                                                                            <div>{'-'}</div>
                                                                                            {/* <div>IP  :  {item.ip ? item.ip : 'N/A'}</div>
                                                                                            <div>Country  : {item.country ? item.country : 'N/A'}</div> */}
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            :
                                                                            item.logType === "ApproveTransaction" ?
                                                                                <div className="user-activities-box-underder">
                                                                                    <Row>
                                                                                        <Col md={4}>
                                                                                            <div className="user-activities-main">
                                                                                                <label>
                                                                                                    Approve Transaction
                                                                                                </label>
                                                                                                <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md={8}>
                                                                                            <div className="user-activities-main">
                                                                                                <div>Token Address : {item && item.requestObject && item.requestObject.tokenAddress ? `${item.requestObject.tokenAddress}` : '-'}</div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                                :
                                                                                item.logType === "RejectTransaction" ?
                                                                                    <div className="user-activities-box-underder">
                                                                                        <Row>
                                                                                            <Col md={4}>
                                                                                                <div className="user-activities-main">
                                                                                                    <label>
                                                                                                        Reject Transaction
                                                                                                    </label>
                                                                                                    <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col md={8}>
                                                                                                <div className="user-activities-main">
                                                                                                    <div>From Token : {item.requestObject?.fromToken ? `${item.requestObject.fromToken}` : '-'}</div>
                                                                                                    <div>To Token : {item.requestObject?.toToken ? `${item.requestObject.toToken}` : '-'}</div>
                                                                                                    <div>From Amount : {item.requestObject?.fromAmount ? `${item.requestObject.fromAmount}` : '-'} {item.requestObject?.fromTokenPriceUSD ? `($${item.requestObject.fromTokenPriceUSD})` : ""}</div>
                                                                                                    <div>To Amount : {item.requestObject?.toAmount ? `${item.requestObject.toAmount}` : '-'} {item.requestObject?.toTokenPriceUSD ? `($${item.requestObject.toTokenPriceUSD})` : ""}</div>
                                                                                                    <div>Transaction Type : {item.requestObject?.transactionType ? `${item.requestObject.transactionType}` : '-'}</div>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                    :
                                                                                    item.logType === "ApproveAllowance" ?
                                                                                        <div className="user-activities-box-underder">
                                                                                            <Row>
                                                                                                <Col md={4}>
                                                                                                    <div className="user-activities-main">
                                                                                                        <label>
                                                                                                            Approve Allowance
                                                                                                        </label>
                                                                                                        <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col md={8}>
                                                                                                    <div className="user-activities-main">
                                                                                                        <div>Token Address : {item && item.requestObject && item.requestObject.tokenAddress ? `${item.requestObject.tokenAddress}` : '-'}</div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                        :
                                                                                        item.logType === "Quote" ?
                                                                                            <div className="user-activities-box-underder">
                                                                                                <Row>
                                                                                                    <Col md={4}>
                                                                                                        <div className="user-activities-main">
                                                                                                            <label>
                                                                                                                Quote
                                                                                                            </label>
                                                                                                            <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col md={8}>
                                                                                                        <div className="user-activities-main">
                                                                                                            <div>{'-'}</div>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                            :
                                                                                            item.logType === "LimitOrderExpired" ?
                                                                                            <div className="user-activities-box-underder">
                                                                                                <Row>
                                                                                                    <Col md={4}>
                                                                                                        <div className="user-activities-main">
                                                                                                            <label>
                                                                                                                Limit Order Expired
                                                                                                            </label>
                                                                                                            <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col md={8}>
                                                                                                        <div className="user-activities-main">
                                                                                                            <div>From Token : {item?.fromTokens?.[0] ? `${item?.fromTokens?.[0]?.address}` : '-'}</div>
                                                                                                            <div>To Token : {item?.toTokens?.[0]  ? `${item?.toTokens?.[0]?.address}` : '-'}</div>
                                                                                                            <div>From Amount : {item.responseObject?.fromAmount? `${item.responseObject.fromAmount}` : '-'} {item.responseObject?.usdAmount ? `($${item.responseObject.usdAmount})` : ""}</div>
                                                                                                            <div>To Amount : {item.responseObject?.toAmount ? `${item.responseObject.toAmount}` : '-'} {item.responseObject?.toUsdAmount ? `($${item.responseObject?.toUsdAmount})` : ""}</div>
                                                                                                            <div>Transaction Type : {item.responseObject?.transactionType ? 'Limit order' : '-'}</div>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                            :
                                                                                            item.logType === "LimitCancelTransaction" ?
                                                                                            <div className="user-activities-box-underder">
                                                                                                <Row>
                                                                                                    <Col md={4}>
                                                                                                        <div className="user-activities-main">
                                                                                                            <label>
                                                                                                                Limit Order Cancel
                                                                                                            </label>
                                                                                                            <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col md={8}>
                                                                                                        <div className="user-activities-main">
                                                                                                            <div>From Token : {item?.fromTokens?.[0] ? `${item?.fromTokens?.[0]?.address}` : '-'}</div>
                                                                                                            <div>To Token : {item?.toTokens?.[0]  ? `${item?.toTokens?.[0]?.address}` : '-'}</div>
                                                                                                            <div>From Amount : {item.responseObject?.fromAmount? `${item.responseObject.fromAmount}` : '-'} {item.responseObject?.usdAmount ? `($${item.responseObject.usdAmount})` : ""}</div>
                                                                                                            <div>To Amount : {item.responseObject?.toAmount ? `${item.responseObject.toAmount}` : '-'} {item.responseObject?.toUsdAmount ? `($${item.responseObject?.toUsdAmount})` : ""}</div>
                                                                                                            <div>Transaction Type : {item.responseObject?.transactionType ? 'Limit order' : '-'}</div>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                            :
                                                                                            item.logType === "LimitOrderExecutedTransaction" ?
                                                                                            <div className="user-activities-box-underder">
                                                                                                <Row>
                                                                                                    <Col md={4}>
                                                                                                        <div className="user-activities-main">
                                                                                                            <label>
                                                                                                                Limit Order Executed
                                                                                                            </label>
                                                                                                            <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col md={8}>
                                                                                                        <div className="user-activities-main">
                                                                                                            <div>From Token : {item?.fromTokens?.[0] ? `${item?.fromTokens?.[0]?.address}` : '-'}</div>
                                                                                                            <div>To Token : {item?.toTokens?.[0]  ? `${item?.toTokens?.[0]?.address}` : '-'}</div>
                                                                                                            <div>From Amount : {item.responseObject?.fromAmount? `${item.responseObject.fromAmount}` : '-'} {item.responseObject?.usdAmount ? `($${item.responseObject.usdAmount})` : ""}</div>
                                                                                                            <div>To Amount : {item.responseObject?.toAmount ? `${item.responseObject.toAmount}` : '-'} {item.responseObject?.toUsdAmount ? `($${item.responseObject?.toUsdAmount})` : ""}</div>
                                                                                                            <div>Transaction Type : {item.responseObject?.transactionType ? 'Limit order' : '-'}</div>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                            :
                                                                                            item.logType === "SaveLimitOrderTransaction" ?
                                                                                            <div className="user-activities-box-underder">
                                                                                                <Row>
                                                                                                    <Col md={4}>
                                                                                                        <div className="user-activities-main">
                                                                                                            <label>
                                                                                                                Limit Order Created
                                                                                                            </label>
                                                                                                            <p>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col md={8}>
                                                                                                        <div className="user-activities-main">
                                                                                                            <div>From Token : {item?.fromTokens?.[0] ? `${item?.fromTokens?.[0]?.address}` : '-'}</div>
                                                                                                            <div>To Token : {item?.toTokens?.[0]  ? `${item?.toTokens?.[0]?.address}` : '-'}</div>
                                                                                                            <div>From Amount : {item.responseObject?.fromAmount? `${item.responseObject.fromAmount}` : '-'} {item.responseObject?.usdAmount ? `($${item.responseObject.usdAmount})` : ""}</div>
                                                                                                            <div>To Amount : {item.responseObject?.toAmount ? `${item.responseObject.toAmount}` : '-'} {item.responseObject?.toUsdAmount ? `($${item.responseObject?.toUsdAmount})` : ""}</div>
                                                                                                            <div>Transaction Type : {item.responseObject?.transactionType ? 'Limit order' : '-'}</div>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                }
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="no-data-found">

                                                    <img
                                                        src={
                                                            require("../../assets/images/no-data.svg")
                                                                .default
                                                        }
                                                        alt="no-data-image"
                                                    />
                                                    <h3 className="text-center text-light">
                                                        No data found
                                                    </h3>

                                                </div>
                                    }

                                </div>
                                {
                                    userActivitiesList.length > 0 && totalItems > 20 && !loader ?
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={20}
                                            totalItemsCount={totalItems}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                }
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};
