import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { pineappleDexService } from "../../service/api.service";
import swal from "sweetalert";
import { InlineLoader, ThreeDotSpinner } from "../../common/loader/loader"

export const ChangePassword = () => {
  const md5 = require("md5");
  const [loader, setLoader] = useState(false);
  const [apierror, setApierror] = useState("");
  const navigate = useNavigate();
  const [oldpassword, setOldpassword] = useState("");
  const [oldpassworderror, setOldpassworderror] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [confirmPassword, setConfirmPassword]=useState("")
  const [newpassworderror, setNewpassworderror] = useState("");
  const [confirmpasswordError, setconfirmpasswordError] =useState("")
  const [showpassword, setShowPasword] = useState(false)
  const [showcpassword, setShowcpassword] = useState(false)
  const [showccpassword, setShowccpassword]=useState(false)
  const PASSWORDREGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  function onChangeOldPassword(e) {
    const data = e;
    setOldpassword(data);
    if (!data) {
      setOldpassworderror("Please enter the password.");
    } else {
      setOldpassworderror("");
    }
  }

  function onChangeNewPassword(e) {
    const data = e;
    setNewpassword(e);
    if (!data) {
      setNewpassworderror("Please enter the new password.");
    } else if (!PASSWORDREGEX.test(data)) {
      setNewpassworderror(
        "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
      );
    } 
    else if (oldpassword ==data) {
      setNewpassworderror(
        "Old and New password should not be same."
      );
    }
    else {
      setNewpassworderror("");
    }
  }

  function onChangeConfirmPassWord(e) {
    const data = e;
    setConfirmPassword(e)
    if (!data) {
      setconfirmpasswordError("Please enter the confirm password.");
    }
    else if (newpassword !==data) {
      setconfirmpasswordError(
        "New password and confirm password doesn't match."
      );
    }

     else if (!PASSWORDREGEX.test(data)) {
      setconfirmpasswordError(
        "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
      );
    }
     else {
      setconfirmpasswordError("");
    }
  }

  const sanitizeInput = (input) => {
    // javascript:// aur data:// schemes ko replace karo
    input = input.replace(/(javascript|data):/gi, "");
  
    // CRLF characters ko replace karo
    input = input.replace(/[\r\n]/g, "");
  
    return input;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Clear previous errors
    setOldpassworderror("");
    setNewpassworderror("");
    setconfirmpasswordError("");
  
    // Validate fields
    let valid = true;
  
    if (!oldpassword) {
      setOldpassworderror("Please enter the old password.");
      valid = false;
    }
  
    if (!newpassword) {
      setNewpassworderror("Please enter the new password.");
      valid = false;
    } else if (!PASSWORDREGEX.test(newpassword)) {
      setNewpassworderror(
        "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
      );
      valid = false;
    } else if (oldpassword === newpassword) {
      setNewpassworderror("Old and New password should not be same.");
      valid = false;
    }
  
    if (!confirmPassword) {
      setconfirmpasswordError("Please enter the confirm password.");
      valid = false;
    } else if (!PASSWORDREGEX.test(confirmPassword)) {
      setconfirmpasswordError(
        "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
      );
      valid = false;
    } else if (newpassword !== confirmPassword) {
      setconfirmpasswordError("New password and confirm password doesn't match.");
      valid = false;
    }
  
    if (!valid) return;
  
    const data = {
      oldPassword: sanitizeInput(oldpassword), // oldPassword:oldpassword,
      password: sanitizeInput(newpassword), // password:newpassword,
    };
  
    swal({
      title: "Are you sure?",
      text: "You want to change the password.",
      icon: "warning",
      buttons: ["Cancel", "Yes, Change Password"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        setLoader(true);
        pineappleDexService
          .changePassword(data)
          .then((res) => {
            if (res?.status === 200) {
              setLoader(false);
              swal("success", res?.data?.message, "success").then(() => {
                localStorage.clear();
                setTimeout(() => {
                  navigate("/");
                }, 1000);
              });
            }
          })
          .catch((error) => {
            setLoader(false);
            if (error?.response?.status === 401) {
              swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                localStorage.clear();
                window.location.href = "/";
              });
            } else {
              swal({ icon: 'error', text: error?.response?.data?.message, button: "OK" });
            }
          });
      }
    });
  };
  

  // const handleSubmit = (e) => {
  //   const data = {
  //     old_password: oldpassword,
  //     new_password: newpassword,
  //   };
  //   e.preventDefault();
  //   if (!oldpassword) {
  //     setOldpassworderror("Please enter the old password.");
  //   } else {
  //     setOldpassworderror("");
  //   }
  //   if (!newpassword) {
  //     setNewpassworderror("Please enter the new password.");
  //   } else if (!PASSWORDREGEX.test(newpassword)) {
  //     setNewpassworderror(
  //       "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
  //     );
  //   }
  //   else if(oldpassword == newpassword){
  //     setNewpassworderror("Old and New password should not be same.")
  //   }
  //   if (!confirmPassword) {
  //     setconfirmpasswordError("Please enter the confirm password.");
  //   } 
  //   else if (!PASSWORDREGEX.test(confirmPassword)) {
  //     setconfirmpasswordError(
  //       "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
  //     );
  //   } 
  //   else if( newpassword !== confirmPassword){
  //     setconfirmpasswordError("New password and confirm password doesn't match.")
  //   }
  //    else {
  //     setNewpassworderror("");
  //     setconfirmpasswordError("")
  //   }
  //   if (oldpassword && newpassword && PASSWORDREGEX.test(newpassword) && oldpassword == newpassword && confirmPassword && PASSWORDREGEX.test(confirmPassword) && newpassword !== confirmPassword) {
  //     swal({
  //       title: "Are you sure?",
  //       text: "you want to change the password.",
  //       icon: "warning",
  //       buttons: ["Cancel", "Yes, Change Password"],
  //       dangerMode: true,
  //     }).then((confirmed) => {
  //       if (confirmed) {
  //         setLoader(true);
  //         helprService
  //           .changePassword(data)
  //           .then((res) => {
  //             if (res?.status == 200) {
  //               setLoader(false);
  //               swal("success", res?.data?.detail, "success").then((value) => {
  //                 localStorage.clear();
  //                 setTimeout(() => {
  //                   navigate("/");
  //                 }, 1000)
  //               });
  //             }
  //           })
  //           .catch((error) => {
  //             if (error?.response?.status == 401) {
  //               setLoader(false)
  //               swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
  //                 localStorage.clear()
  //                 window.location.href = "/";

  //               })
  //             } else {
  //               swal({ icon: 'error', text: error?.response?.data?.detail, button: "OK", }).then((value) => {
  //                 setLoader(false)
  //               })
  //             }
  //           });
  //       }
  //     });
  //   }
  // };

  return (
    <>
      <div className="dashboard-main-area min-height password-outer">
      {loader ? <ThreeDotSpinner className="loginPageLoader" /> : ""}
        <Container fluid>
          <Row className="justify-content-center">
            <Col xl={4}>
              <div className="login-content-area">
                <h2 className="chamge-text">Change Password</h2>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group className="mb-1 position-relative">
                    <Form.Label>Old Password</Form.Label>
                    {!showpassword ? <i className="fa-solid fa-eye-slash eye-icon" onClick={(e) => setShowPasword(true)}></i>
                      :
                      <i className="fa-solid fa-eye eye-icon" onClick={(e) => setShowPasword(false)}></i>}
                    <Form.Control
                      type={showpassword ? "text" : "password"}
                      placeholder="Enter Old Password"
                      onChange={(e) => onChangeOldPassword(e.target.value)}
                      onKeyDown={(e) => {
                        if (!e.target.value && e.code === "Space") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span className="text-danger">{oldpassworderror}</span>
                  </Form.Group>
                  <Form.Group className="mb-1 position-relative">
                    <Form.Label>New Password</Form.Label>
                    {!showcpassword ?
                      <i className="fa-solid fa-eye-slash eye-icon" onClick={(e) => setShowcpassword(true)}></i> :
                      <i className="fa-solid fa-eye eye-icon" onClick={(e) => setShowcpassword(false)}></i>}
                    <Form.Control
                      type={showcpassword ? "text" : "password"}
                      placeholder="Enter New Password"
                      onChange={(e) => onChangeNewPassword(e.target.value)}
                      onKeyDown={(e) => {
                        if (!e.target.value && e.code === "Space") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span className="text-danger">{newpassworderror}</span>
                  </Form.Group>
                  <Form.Group className="mb-1 position-relative">
                    <Form.Label>Confirm Password</Form.Label>
                    {!showccpassword ?
                      <i className="fa-solid fa-eye-slash eye-icon" onClick={(e) => setShowccpassword(true)}></i> :
                      <i className="fa-solid fa-eye eye-icon" onClick={(e) => setShowccpassword(false)}></i>}
                    <Form.Control
                      type={showccpassword ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      onChange={(e) => onChangeConfirmPassWord(e.target.value)}
                      onKeyDown={(e) => {
                        if (!e.target.value && e.code === "Space") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span className="text-danger">{confirmpasswordError}</span>
                  </Form.Group>
                  <Button className="w-75" variant="primary" type="submit">
                    {loader ? <InlineLoader /> : "Submit"}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};