import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { TradingGraph } from "../../common/tradingView";

export const TokenDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location",location.state?.item?.symbol)

  return (
    <div className="dashboard-main-area">
      <Container fluid>
        <div className="dashboard-area-heading management-heading">
          <Row className="row justify-content-center align-items-center mb-3">
            <Col md={12}>
              <Row className="justify-content-between">
                <Col md={12} className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="back-btn-main me-4"
                      onClick={() => navigate(-1)}
                    >
                      <i className="fa-solid fa-arrow-left me-2"></i>
                      Back
                    </div>
                    <h2 className="align-self-center m-0 inner-details-heading">
                      Token Detail
                    </h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pb-4 justify-content-center">
            <div className="user-activities-box py-2">
              <Col>
                <div className="user-activities-main">
                  <div className="d-flex align-items-center">
                    <span className="WETH-text">Icon with text: </span>
                    {location.state?.item.logoURI ?
                    <img className='under-token-img'
                        src={location.state?.item.logoURI}
                        alt="" /> :
                        <div 
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '20px',
                                color: 'rgb(255, 255, 255)',
                                marginRight: '5px',
                                width: '40px',
                                backgroundColor: '#fce02570',
                                padding: '4px',
                                borderRadius: '50%',
                                minWidth: '40px',
                                height: '40px',
                                minHeight: '40px',
                                fontWeight: '700',
                                border: '4px solid #1f2125',
                            }}
                        >
                          {location.state?.item.name?.charAt(0)?.toUpperCase() || 'N/A'}
                        </div>}
                   
                    <span className="WETH-text">{location.state?.item.symbol}</span>
                  </div>
                  <div>Full Name : {location.state?.item.name}</div>
                </div>
              </Col>
            </div>
          </Row>
          <Row className="pb-4 justify-content-center">
            <Col
              md={12}
              lg={8}
              xl={8}
              xxl={8}
              className="tranding-view-shimmer"
            >
            <div className="tradingview-widget-container w-full max-w-[800px]">
              <div className="iframe-container" style={{ position: 'relative' }}>
                <iframe
                  src={`https://dexscreener.com/ethereum/${location.state?.item?.address}?embed=1&trades=0&info=0`}
                  title="dexscreener chart"
                  style={{ width: '100%', height: '500px', border: 'none' }}
                />
                <div className="hidebar h-[50px] w-full"></div>
              </div>
            </div>
             
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
