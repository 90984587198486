import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { pineappleDexService } from "../../service/api.service";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import { FaCopy } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { OverLayAddressTooltip } from "../../common/Functions";
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { getItem, setItem } from "../../util/localStorage";
import { ReactSelectSearchableDropdown } from "../../common/reactSelectDropdown";
import { DateRangeFilter } from "../../common/dateRange";
import userImg from "../../assets/images/byDefaultUser.png";


export const LimitOrdersList = () => {
  const [limitOrdersList, setLimitOrdersList] = useState([]);
  const [limitOrdersData, setLimitOrdersData] = useState(null);

  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [filter, setFilter] = useState({ name: "All", id: "" });
  const [dateFilter, setDateFilter] = useState({ start: "", end: "" });

  const [search, setSearch] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);

  const [copied, setCopied] = useState(false);
  const [copiedValue, setCopiedValue] = useState(null);

  const [toCopied, setToCopied] = useState(false);
  const [fromCopiedValue, setFromCopiedValue] = useState(null);
  const [copiedUserAddress, setCopiedUserAddress] = useState(false);


  const [mouseHoverAddressValue, setMouseHoverAddressValue] = useState('');

  //From and to filter
  const [tokensList, setTokensList] = useState([]);
  const [tokensList2, setTokensList2] = useState([]);
  const [selectedFromToken, setSelectedFromToken] = useState({ label: 'From - All', id: 0, name: 'From - All' });
  const [selectedToToken, setSelectedToToken] = useState({ label: 'To - All', id: 0, name: 'To - All' });
  const [mouseHoverToTokenValue, setMouseHoverToTokenValue] = useState('');
  const [copiedIndex, setCopiedIndex] = useState(null);
  const { state } = useLocation();


  const filtersList = [
    {
      name: "All",
      id: "",
    },
    {
      name: "Active",
      id: "PENDING",
    },
    {
      name: "Fulfilled",
      id: "COMPLETE",
    },
    {
      name: "Canceled",
      id: "CANCELLED",
    },
    {
      name: "Failed",
      id: "FAILED",
    },
    {
      name: "Expired",
      id: "EXPIRED",
    },
  ];


  useEffect(() => {
    let searchValue = getItem("user_list_search");                  //set search from local storage | when the user search and navigate to the user activity screen and after back on user list the search will show filled as previous search
    if (state === "from_user_activities_screen" && searchValue) {
      setSearch(searchValue);
      setLoader(true);
      getLimitOrdersList(0, searchValue);
    }
    else {
      setLoader(true);
      getLimitOrdersList(0);
    }
    getTokenList();
  }, []);


  useEffect(() => {
    setLoader(true);
    getLimitOrdersList(page, search, dateFilter?.start, dateFilter?.end, selectedFromToken, selectedToToken, filter);
  }, [page, search, selectedFromToken, selectedToToken, dateFilter, filter]);


  const getTokenList = async () => {
    try {
      const response = await pineappleDexService.getTokensList();
      if (response?.status === 200) {
        let list = response.data && response.data.tokens ? response.data.tokens : [];
        let list2 = response.data && response.data.tokens ? response.data.tokens : [];
        // Prepare the list with "All" option
        list = [
          {
            "_id": 0,
            "label": "From - All",
            "address": "",
            "symbol": "From - All",
            "decimals": 18,
            "name": "From - All",
            "logoURI": "",
            "eip2612": false,
            "createdAt": "",
            "updatedAt": "",
            "__v": 0
          },
          ...list.map(token => ({ ...token, label: token.symbol })) // Ensure each token has a 'label'
        ];
        list2 = [
          {
            "_id": 1,
            "label": "To - All",
            "address": "",
            "symbol": "To - All",
            "decimals": 1,
            "name": "To - All",
            "logoURI": "",
            "eip2612": false,
            "createdAt": "",
            "updatedAt": "",
            "__v": 1
          },
          ...list2.map(token => ({ ...token, label: token.symbol }))
        ];
        setTokensList2(list2);           // To show from and to token selected as "From - All" & "To - All" values by default
        setTokensList(list);
      }
    } catch (error) {
      console.log('TOKEN LIST API ERROR:', error);
    }
  };


  const getLimitOrdersList = async (page, search, startDate, endDate, fromToken, toToken, transactionStatus) => {
    try {
      const params = {
        page: page,
        keyword: search ? search : '',
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        ft: fromToken?.address ? fromToken?.address : '',
        tt: toToken?.address ? toToken?.address : '',
        transactionStatus: filter && filter.id ? filter.id : ''
      }
      const response = await pineappleDexService.getLimitOrderData(params);
      if (response?.status == 200) {
        // console.log("response with limit order------->>", response?.data?.data)
        let totalCount = response.data.data && response.data.data.totalCount ? response.data.data.totalCount : 0;
        let list = response.data && response.data.data ? response.data.data : [];
        let usersGlobalData = response.data.data ? response.data.data : null;
        console.log("usersGlobalData", usersGlobalData)
        setLimitOrdersData(usersGlobalData);
        setLimitOrdersList(list);
        setTotalItems(totalCount);
        setLoader(false);
        setSearchLoader(false);
        if (search) {
          setItem("user_list_search", search);
        }
        else {
          localStorage.removeItem("user_list_search");
        }
      }
    }
    catch (error) {
      setLoader(false);
      setSearchLoader(false);
      if (error?.response?.status == 401) {
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          () => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }
  };


  const handlePageChange = (pageNumber) => {
    let page = pageNumber - 1
    setPage(page);
    setActivePage(pageNumber);
    setLoader(true);
    getLimitOrdersList(page, search, filter);
  };



  const handleCopy = (address, type) => {
    if (type === 'fromAddress') {
      setFromCopiedValue(true);
      setCopiedValue(address);
      setTimeout(() => setFromCopiedValue(false), 2000);
    }
    else if (type === 'toAddress') {
      setToCopied(true);
      setCopiedValue(address);
      setTimeout(() => setToCopied(false), 2000);
    }
    else if (type === 'userAddress') {
      setCopiedUserAddress(true);
      setCopiedValue(address);
      setTimeout(() => setCopiedUserAddress(false), 2000);
    }
    else;
  };

  const handleAddressCopy = (address, idx) => {
    setCopiedUserAddress(true);
    setCopiedIndex(idx);
    setCopiedValue(address);
    setTimeout(() => setCopiedUserAddress(false), 2000);
  };



  const searchData = useCallback(debounce(getLimitOrdersList, 1000), []);

  return (
    <>
      <div className="dashboard-main-area limit-order-section">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <div className="margin50 under-boes-list">
              <div className="width-20">
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-cart-shopping"></i></div>
                      <div>
                        <h5>Total Orders</h5>
                        <h3>{limitOrdersData && limitOrdersData.totalTransactions ? limitOrdersData.totalTransactions : '0'}</h3>
                      </div>
                    </div>
                }
              </div>
              <div className="width-20">
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-coins"></i></div>
                      <div>
                        <h5>Active Orders</h5>
                        <h3>{limitOrdersData && limitOrdersData.activeCount ? limitOrdersData.activeCount : '0'}</h3>
                      </div>
                    </div>

                }
              </div>
              <div className="width-20">
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-shuffle"></i></div>
                      <div>
                        <h5>Fullfilled Orders</h5>
                        <h3>{limitOrdersData && limitOrdersData.complete ? limitOrdersData.complete : '0'}</h3>
                      </div>
                    </div>

                }
              </div>
              <div className="width-20">
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-road"></i></div>
                      <div>
                        <h5>Canceled Orders</h5>
                        <h3>{limitOrdersData && limitOrdersData.cancelledCount ? limitOrdersData.cancelledCount : '0'}</h3>
                      </div>
                    </div>
                }
              </div>
              <div className="width-20">
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-road"></i></div>
                      <div>
                        <h5>Expired Orders</h5>
                        <h3>{limitOrdersData && limitOrdersData.expiredCount ? limitOrdersData.expiredCount : '0'}</h3>
                      </div>
                    </div>
                }
              </div>

            </div>
            <Row className="row justify-content-center align-items-center mb-2">
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Limit Orders</h2>
                  </div>

                  <div className="search-area main-search-area d-flex">
                    <div className="position-relative w-100 react-select-main">
                      <DateRangeFilter dateFilter={dateFilter} setDateFilter={setDateFilter} />
                    </div>
                    <div className="position-relative w-100 react-select-main">
                      <ReactSelectSearchableDropdown
                        placeholder="Select from token"
                        value={selectedFromToken}
                        openMenuOnFocus={true}
                        list={tokensList ? tokensList : []}
                        onChange={(item) => {
                          setSearch('');
                          setSelectedFromToken(item);
                        }}
                      />
                    </div>
                    <div className="position-relative w-100 mx-2 react-select-main">
                      <ReactSelectSearchableDropdown
                        placeholder="Select to token"
                        value={selectedToToken}
                        openMenuOnFocus={true}
                        list={tokensList2 ? tokensList2 : []}
                        onChange={(item) => {
                          setSearch('');
                          setSelectedToToken(item);
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <form
                        autoComplete="off"
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <div className="position-relative width-inner-main">
                          <input
                            type="text"
                            value={search}
                            className="form-control inner-input"
                            placeholder="Search by wallet, pair & token address..."
                            onChange={(e) =>
                            (
                              setSearchLoader(true),
                              searchData(page, e.target.value),
                              setSearch(e.target.value)
                            )
                            }
                            onKeyDown={(e) => {
                              if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                              }
                            }}
                          />
                          {
                            searchLoader ?
                              <span class="loader-search"></span>
                              :
                              <i
                                className="fa fa-search"
                                aria-hidden="true"
                              ></i>
                          }
                          <i className="fa fa-close d-none" aria-hidden="true"></i>
                        </div>
                      </form>
                      <div className="all-dropdown-list">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant={"secondary"}
                            id="dropdown-basic">
                            {filter?.name}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {
                              filtersList.map((item) => (
                                filter?.name !== item.name &&
                                <Dropdown.Item
                                  onClick={() => setFilter(item)}
                                >
                                  {item.name}
                                </Dropdown.Item>
                              ))
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="pb-4 justify-content-center">
              <Col md={12}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>user Wallet Address</th>
                        <th>Order Type</th>
                        {/* <th>Token Pair</th> */}
                        <th>From Token</th>
                        <th>From Amount</th>
                        <th>To Token</th>
                        <th>To Amount</th>
                        <th>Order Amount</th>
                        <th>Created Date & Time</th>
                        <th>Order Expiry</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loader ?
                          (
                            <>
                              {[...Array(10)].map((_, index) => (
                                <tr key={index}>
                                  {[...Array(12)].map((_, i) => <td key={i}><ShimmerThumbnail /></td>)}
                                </tr>
                              ))}
                            </>
                          )
                          :
                          limitOrdersList && limitOrdersList?.data?.length > 0 ? (
                            limitOrdersList?.data?.map((data, index) => {

                              const serialNumber = (activePage - 1) * 10 + index + 1;
                              const fullToTokenAddress = data.toToken && data.toToken.address ? data.toToken.address : '';
                              const fullFromTokenAddress = data.fromToken && data.fromToken.address ? data.fromToken.address : '';

                              const formattedToTokenAddress = data.toToken?.address
                                ? `${data.toToken.address.slice(0, 5)}...${data.toToken.address.slice(-5)}`
                                : 'N/A';
                              const formattedFromTokenAddress = data.fromToken?.address
                                ? `${data.fromToken.address.slice(0, 5)}...${data.fromToken.address.slice(-5)}`
                                : 'N/A';

                              const formattedAddress = data.user?.walletAddress
                                ? `${data.user.walletAddress?.slice(0, 5)}...${data.user.walletAddress?.slice(-5)}`
                                : 'N/A';

                              const highValue = data.transactionSum || data && data.transactionSum == 0 ? data.transactionSum : 'N/A'
                              const totalActivities = data.totalTransactions || data && data.totalTransactions == 0 ? data.totalTransactions : 'N/A'
                              const fromTokenToEth = Number(data?.fromAmount)
                              const toAmount = Number(data?.toAmount)

                              return (
                                <tr key={index+1}>
                                  <td>{serialNumber}</td>
                                  <td>
                                      {formattedAddress || "N/A"}
                                      {data.user?.walletAddress && (
                                          <>
                                              <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                  <CopyToClipboard
                                                      text={data.user?.walletAddress}
                                                      onCopy={() => handleAddressCopy(data.user?.walletAddress, index)}
                                                  >
                                                      <button
                                                          style={{
                                                              marginLeft: '8px',
                                                              border: 'none',
                                                              background: 'none',
                                                              cursor: 'pointer'
                                                          }}
                                                      >
                                                          <FaCopy color="white" />
                                                      </button>
                                                  </CopyToClipboard>
                                              </OverLayAddressTooltip>
                                              {copiedUserAddress && copiedIndex === index && (
                                                  <span style={{ marginLeft: '8px', color: '#62fc25', fontSize: '12px' }}>
                                                      Copied!
                                                  </span>
                                              )}
                                          </>
                                      )}
                                  </td>
                                  <td>{data?.transactionType || "N/A"}</td>
                                  <td>
                                    <div className="token-details">
                                      {data?.fromToken?.logoURI ? <img src={data?.fromToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} <span>{data?.fromToken?.symbol || "N/A"}
                                        <div className="d-flex">
                                          <OverLayAddressTooltip overlayValue={fullFromTokenAddress} defaultShow={true}>
                                            <span
                                              style={mouseHoverAddressValue == fullFromTokenAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 2px" } : { borderRadius: '5px', padding: "1px 2px" }}
                                              onMouseEnter={() => setMouseHoverAddressValue(fullFromTokenAddress)}
                                              onMouseLeave={() => setMouseHoverAddressValue('')}
                                            >
                                              {formattedFromTokenAddress}
                                            </span>
                                          </OverLayAddressTooltip>
                                          {fullFromTokenAddress && (
                                            <>
                                              <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                <CopyToClipboard text={fullFromTokenAddress} onCopy={() => handleCopy(index, "fromAddress")}>
                                                  <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                    <FaCopy />
                                                  </button>
                                                </CopyToClipboard>
                                              </OverLayAddressTooltip>
                                              {fromCopiedValue && (copiedValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                            </>
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{fromTokenToEth ? fromTokenToEth : 'N/A'}</td>
                                  <td>
                                    <div className="token-details">
                                      {data?.toToken?.logoURI ? <img src={data?.toToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />}<span> {data?.toToken?.symbol || "N/A"}
                                        <div className="d-flex">
                                          <OverLayAddressTooltip overlayValue={fullToTokenAddress}>
                                            <span
                                              style={mouseHoverToTokenValue == fullToTokenAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 2px" } : { borderRadius: '5px', padding: "1px 2px" }}
                                              onMouseEnter={() => setMouseHoverToTokenValue(fullToTokenAddress)}
                                              onMouseLeave={() => setMouseHoverToTokenValue('')}
                                            >
                                              {formattedToTokenAddress}
                                            </span>
                                          </OverLayAddressTooltip>
                                          {fullToTokenAddress && (
                                            <>
                                              <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                <CopyToClipboard text={fullToTokenAddress} onCopy={() => handleCopy(index, "toAddress")}>
                                                  <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                    <FaCopy />
                                                  </button>
                                                </CopyToClipboard>
                                              </OverLayAddressTooltip>
                                              {toCopied && (copiedValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                            </>
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{toAmount ? toAmount : 'N/A'}</td>
                                  <td>{toAmount ? toAmount : 'N/A'}</td>
                                  {/* <td>{data.usdAmount ? data.usdAmount : 'N/A'}</td> */}
                                  <td>{moment(data.createdAt).format("DD-MM-YYYY hh:mm A") || "N/A"}</td>
                                  <td>{moment(data.expireDateTime).format("DD-MM-YYYY hh:mm A") || "N/A"}</td>
                                  {/* <td>
                                  <div className="address-btn">
                                    <OverLayAddressTooltip overlayValue={data.walletAddress}>
                                      <span
                                        style={mouseHoverAddressValue == data.walletAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 4px" } : {}}
                                        onMouseEnter={() => setMouseHoverAddressValue(data.walletAddress)}
                                        onMouseLeave={() => setMouseHoverAddressValue('')}
                                      >
                                        {"N/A"}
                                      </span>
                                    </OverLayAddressTooltip>
                                    {data.walletAddress && (
                                      <>
                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                          <CopyToClipboard text={data.walletAddress} onCopy={() => handleCopy(index)}>
                                            <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                              <FaCopy />
                                            </button>
                                          </CopyToClipboard>
                                        </OverLayAddressTooltip>
                                        {copied && copiedValue === index && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                      </>
                                    )}
                                    </div>
                                  </td> */}
                                  {/* <td>{moment(data.lastLoginDate).format("DD-MM-YYYY hh:mm A") || "N/A"}</td> */}
                                  <td>
                                    {(() => {
                                      switch (data.transactionStatus) {
                                        case "COMPLETE":
                                          return (
                                            <div className="completed-transation">
                                              <i className="fa-solid fa-check me-1"></i> COMPLETE
                                            </div>
                                          );
                                        case "PENDING":
                                          return (
                                            <div className="completed-transation pending" >
                                              <i className="fa-regular fa-clock me-1"></i> PENDING
                                            </div>
                                          );
                                        case "FAILED":
                                          return (
                                            <div className="completed-transation failed" >
                                              <i className="fa-solid fa-xmark me-1"></i> FAILED
                                            </div>
                                          );
                                          case "EXPIRED":
                                            return (
                                              <div className="completed-transation failed" >
                                                <i className="fa-solid fa-xmark me-1"></i> EXPIRED
                                              </div>
                                            );
                                        case "CANCELLED":
                                          return (
                                            <div className="completed-transation failed" >
                                              <i className="fa-solid fa-xmark me-1"></i> CANCELLED
                                            </div>
                                          );
                                        default:
                                          return null;
                                      }
                                    })()}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/limit-order-detail?id=${data._id}`}
                                      state={{ userWallerAddress: data.walletAddress ? data.walletAddress : '' }}
                                    >
                                      <div className="view-btn-main" style={{ cursor: 'pointer' }}>
                                        < i className="fa-solid fa-eye"></i>
                                      </div>
                                    </Link>
                                  </td>
                                </tr>
                              )
                            })) : (
                            <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="no-data-image"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                              </td>
                            </tr>
                          )}
                    </tbody>
                  </Table>
                  {limitOrdersList.data?.length > 0 && totalItems > 10 && !loader ?
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                    : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
