import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { object, string } from "yup";
import { pineappleDexService } from "../../service/api.service";
import { setItem, getItem } from "../../util/localStorage";
import swal from "sweetalert";
import { GlobalContext } from "../../globalState/GlobalContext";
import { ThreeDotSpinner } from "../../common/loader/loader";
import ReCAPTCHA from "react-google-recaptcha";

export const Login = () => {
  const md5 = require('md5');
  const [inputCaptcha, setInputCaptcha] = useState(''); // ReCAPTCHA token
  const [captchaError, setCaptchaError] = useState('');
  const [loginObj, setLoginObj] = useState({
    email: "",
    password: "",
    role: "Admin",
  });
  const globalStates = useContext(GlobalContext);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const requestFormSchema = object({
    email: string().email('Email must be a valid email.').required("Email is required.").trim(),
    password: string().required("Password is required.").trim(),
  });

  useEffect(() => {
    if (getItem("accessToken")) {
      navigate("/home");
    }
  }, [navigate]);

  const sanitizeInput = (input) => {
    input = input.replace(/(javascript|data):/gi, "");
    input = input.replace(/[\r\n]/g, "");
    return input;
  };

  async function handleFormSubmit(values) {
    if (!inputCaptcha) {
      setCaptchaError('Please complete the CAPTCHA.');
      return;
    }

    setCaptchaError(''); // Clear any previous CAPTCHA errors
    setLoader(true);

    try {
      const data = {
        email: sanitizeInput(values.email),
        password: sanitizeInput(values.password),
        role: "Admin",
        captchaToken: inputCaptcha,
        fcmToken: globalStates?.fcmToken,
      };

      let response = await pineappleDexService.login(data);
      if (response?.status === 200 && response?.data?.data) {
        setItem("accessToken", response?.data?.token);
        localStorage.setItem("login", true);
        navigate("/home");
      }
    } catch (error) {
      swal({
        icon: "error",
        text: error?.response?.data?.message ? error?.response?.data?.message : error?.message,
        button: "OK"
      });
    } finally {
      setLoader(false);
    }
  }

  function onChange(value) {
    setInputCaptcha(value); // Store the CAPTCHA token
  }

  return (
    <div className="login-area">
      {loader && <ThreeDotSpinner className="loginPageLoader" />}
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <img className="logo-login" src={require("../../assets/images/logo-login.svg").default} alt="Logo" />
            <div className="login-content-area">
              <h2>Login</h2>
              <p>Please enter your email address and password.</p>
              <Formik
                initialValues={loginObj}
                validationSchema={requestFormSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  handleFormSubmit(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email Address"
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter Password"
                        id="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      {errors.password && touched.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                    </Form.Group>
                    
                    <ReCAPTCHA
                    sitekey="6LeWlDwqAAAAANA66GkbLrCl_Y_7h9jkhdfMWkGl"
                      // sitekey="6LdTlzwqAAAAAH1EtS6RKFsN1nNUaXeZkLen7tE2"
                      // sitekey="6Lc7lzwqAAAAAK9F94AG5ivwGZe2k9hlvjUIVgDZ"
                      onChange={onChange}
                    />
                    {captchaError && <div className="text-danger">{captchaError}</div>} {/* Display CAPTCHA errors */}

                    <Button className="w-50" variant="primary" type="submit">
                      Login
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
