import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { pineappleDexService } from "../../service/api.service";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import { FaCopy } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { OverLayAddressTooltip } from "../../common/Functions";
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { getItem, setItem } from "../../util/localStorage";


export const UsersList = () => {
  const [userListing, setUserListing] = useState([]);
  const [usersData, setUsersData] = useState(null);

  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [filter, setFilter] = useState("");

  const [search, setSearch] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);

  const [copied, setCopied] = useState(false);
  const [copiedValue, setCopiedValue] = useState(null);

  const [highValueSorting, setHighValueSorting] = useState(null);
  const [highActivitiesSorting, setHighActivitiesSorting] = useState(null);
  const [mouseHoverAddressValue, setMouseHoverAddressValue] = useState('');

  const { state } = useLocation();


  const dropdownList = [
    {
      name: "Deactivated",
      id: 0,
    },
    {
      name: "Activated",
      id: 1,
    },
    {
      name: "Suspended",
      id: 2,
    },
  ];


  useEffect(() => {
    let searchValue = getItem("user_list_search");                  //set search from local storage | when the user search and navigate to the user activity screen and after back on user list the search will show filled as previous search
    if (state === "from_user_activities_screen" && searchValue) {
      setSearch(searchValue);
      setLoader(true);
      getUserList(0, searchValue);
    }
    else {
      setLoader(true);
      getUserList(0);
    }
  }, []);


  const getUserList = async (page, search, filterVal, sortingOn, sortType) => {
    try {
      const params = {
        page: page,
        keyword: search ? search : '',
        sort: sortingOn ? sortingOn : '',
        sortType: sortType ? sortType : '',
        filter: filterVal ? filterVal : ''
      }
      const response = await pineappleDexService.UserList(params);
      if (response?.status == 200) {
        let totalCount = response.data && response.data.totalCount ? response.data.totalCount : 0;
        let list = response.data && response.data.data ? response.data.data : [];
        let usersGlobalData = response.data ? response.data : null;
        setUsersData(usersGlobalData);
        setUserListing(list);
        setTotalItems(totalCount);
        setLoader(false);
        setSearchLoader(false);
        if (search) {
          setItem("user_list_search", search);
        }
        else {
          localStorage.removeItem("user_list_search");
        }
      }
    }
    catch (error) {
      setLoader(false);
      setSearchLoader(false);
      if (error?.response?.status == 401) {
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          () => {
          }
        );
      }
    }
  };


  const handlePageChange = (pageNumber) => {
    let page = pageNumber - 1
    setPage(page);
    setActivePage(pageNumber);
    setLoader(true);
    getUserList(page, search, filter);
  };


  const handleFilters = async (value) => {
    setFilter(value);
    setLoader(true);
    setHighValueSorting('');
    setHighActivitiesSorting('');
    getUserList(page, search, value);
  };


  const handleStatusChange = async (item, userId) => {
    let action = item.name
    let itemId = item.id
    let actionType = action == "Deactivated" ? "Deactivate" : action == "Activated" ? "Activate" : "Suspend"
    if (action == "Deactivated") {
      swal({
        title: 'Are you sure?',
        text: `You want to deactivate this user. Please note that once deactivated, the user cannot be retrieved.`,
        icon: 'warning',
        buttons: ['Cancel', `Yes, Deactivate`],
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          handleUserStaus(itemId, userId);
        }
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: `You want to ${actionType} the user?`,
        icon: 'warning',
        buttons: ['Cancel', `Yes, ${actionType}`],
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          handleUserStaus(itemId, userId);
        }
      })
    }
  };


  const handleUserStaus = async (itemId, userId) => {
    try {
      const params = {
        status: itemId
      }
      const response = await pineappleDexService.userStatus(params, userId);
      if (response.status === 200) {
        setLoader(true);
        getUserList(page, search, filter);
      }
    }
    catch (error) {
      swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
    }
  };


  const handleCopy = (index) => {
    setCopied(true);
    setCopiedValue(index);
    setTimeout(() => setCopied(false), 2000);
  };


  const handleSorting = (sortOn) => {
    if (sortOn === "valueCount") {
      let sorttype = highValueSorting && highValueSorting === "asc" ? "desc" : "asc"
      setHighValueSorting(sorttype);
      setHighActivitiesSorting('');
      getUserList(page, search, filter, "hv", sorttype)
    }
    else if (sortOn === "activityCount") {
      let sorttype = highActivitiesSorting && highActivitiesSorting === "asc" ? "desc" : "asc"
      setHighActivitiesSorting(sorttype);
      setHighValueSorting('');
      getUserList(page, search, filter, "ha", sorttype)
    }
    else;
  };

  const searchData = useCallback(debounce(getUserList, 1000), []);

  return (
    <>
      <div className="dashboard-main-area">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="margin50">
              <Col md={3}>
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-cart-shopping"></i></div>
                      <div>
                        <h5>Total Users</h5>
                        <h3>{usersData && usersData.totalUsers ? usersData.totalUsers : '0'}</h3>
                      </div>
                    </div>
                }
              </Col>
              <Col md={3}>
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-coins"></i></div>
                      <div>
                        <h5>Total Active Users</h5>
                        <h3>{usersData && usersData.totalActiveUser ? usersData.totalActiveUser : '0'}</h3>
                      </div>
                    </div>

                }
              </Col>
              <Col md={3}>
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-shuffle"></i></div>
                      <div>
                        <h5>Total Deactivated Users</h5>
                        <h3>{usersData && usersData.totalDisabledUser ? usersData.totalDisabledUser : '0'}</h3>
                      </div>
                    </div>

                }
              </Col>
              <Col md={3}>
                {
                  loader ?
                    <ShimmerThumbnail className="card-shimmer" />
                    :
                    <div className="card-infor-box">
                      <div className="main-icon-1"><i className="fa-solid fa-road"></i></div>
                      <div>
                        <h5>Active Users in last 24 Hours</h5>
                        <h3>{usersData && usersData.last24Huser ? usersData.last24Huser : '0'}</h3>
                      </div>
                    </div>
                }
              </Col>
            </Row>
            <Row className="row justify-content-center align-items-center mb-2">
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">User Management</h2>
                  </div>

                  <div className="search-area main-search-area">
                    <div className="d-flex align-items-center justify-content-between">
                      <form
                        autoComplete="off"
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <div className="position-relative width-inner-main">
                          <input
                            type="text"
                            value={search}
                            className="form-control inner-input"
                            placeholder="Search by wallet address..."
                            onChange={(e) =>
                            (
                              setSearchLoader(true),
                              searchData(page, e.target.value, filter),
                              setSearch(e.target.value),
                              setHighValueSorting(''),
                              setHighActivitiesSorting('')
                            )
                            }
                            onKeyDown={(e) => {
                              if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                              }
                            }}
                          />
                          {
                            searchLoader ?
                              <span class="loader-search"></span>
                              :
                              <i
                                className="fa fa-search"
                                aria-hidden="true"
                              ></i>
                          }
                          <i className="fa fa-close d-none" aria-hidden="true"></i>
                        </div>
                      </form>
                      <div className="select-days position-relative">
                        <Form.Select
                          aria-label="Default select example"
                          value={filter}
                          onChange={(e) => handleFilters(e.target.value)}
                        >
                          <option value="">All</option>
                          <option value="24h">Last 24 Hours</option>
                          <option value="48h">Last 48 Hours</option>
                          <option value="7d">Last 7 Days</option>
                          <option value="15d">Last 15 Days</option>
                          <option value="30d">Last 30 Days</option>
                        </Form.Select>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="pb-4 justify-content-center">
              <Col md={12}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Wallet Address</th>
                        <th>Country</th>
                        <th>Last Active IP</th>
                        <th>Last Active Session</th>
                        <th>
                          <p className="d-flex align-items-center">
                            <span className="cursor-pointer" onClick={() => handleSorting("valueCount")}>Value Count</span>
                            <span className="span-sort ms-2">
                              {
                                highValueSorting === "desc" ?
                                  <i onClick={() => handleSorting("valueCount")} className="fa-solid fa-sort-up cursor-pointer"></i>
                                  :
                                  highValueSorting === "asc" ?
                                    <i onClick={() => handleSorting("valueCount")} className="fa-solid fa-sort-down cursor-pointer"></i>
                                    :
                                    <>
                                      <i onClick={() => handleSorting("valueCount")} className="fa-solid fa-sort-up cursor-pointer"></i>
                                      <i onClick={() => handleSorting("valueCount")} className="fa-solid fa-sort-down cursor-pointer"></i>
                                    </>
                              }
                            </span>
                          </p>
                        </th>
                        <th>
                          <p className="d-flex align-items-center">
                            <span className="cursor-pointer" onClick={() => handleSorting("activityCount")}>Activity Count</span>
                            <span className="span-sort ms-2">
                              {
                                highActivitiesSorting === "desc" ?
                                  <i onClick={() => handleSorting("activityCount")} className="fa-solid fa-sort-up cursor-pointer"></i>
                                  :
                                  highActivitiesSorting === "asc" ?
                                    <i onClick={() => handleSorting("activityCount")} className="fa-solid fa-sort-down cursor-pointer"></i>
                                    :
                                    <>
                                      <i onClick={() => handleSorting("activityCount")} className="fa-solid fa-sort-up cursor-pointer"></i>
                                      <i onClick={() => handleSorting("activityCount")} className="fa-solid fa-sort-down cursor-pointer"></i>
                                    </>
                              }
                            </span>
                          </p>
                        </th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loader ?
                          (
                            <>
                              {[...Array(10)].map((_, index) => (
                                <tr key={index}>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                  <td><ShimmerThumbnail /></td>
                                </tr>
                              ))}
                            </>
                          )
                          :
                          userListing && userListing?.length > 0 ? (
                            userListing?.map((data, index) => {
                              const serialNumber = (activePage - 1) * 10 + index + 1;

                              const formattedAddress = data.walletAddress
                                ? `${data.walletAddress.slice(0, 5)}...${data.walletAddress.slice(-5)}`
                                : 'N/A';

                              const highValue = data.transactionSum || data && data.transactionSum == 0 ? data.transactionSum : 'N/A'
                              const totalActivities = data.totalTransactions || data && data.totalTransactions == 0 ? data.totalTransactions : 'N/A'

                              return (
                                <tr key={index}>
                                  <td>{serialNumber}</td>
                                  <td>
                                    <OverLayAddressTooltip overlayValue={data.walletAddress}>
                                      <span
                                        style={mouseHoverAddressValue == data.walletAddress ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 4px" } : {}}
                                        onMouseEnter={() => setMouseHoverAddressValue(data.walletAddress)}
                                        onMouseLeave={() => setMouseHoverAddressValue('')}
                                      >
                                        {formattedAddress}
                                      </span>
                                    </OverLayAddressTooltip>
                                    {data.walletAddress && (
                                      <>
                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                          <CopyToClipboard text={data.walletAddress} onCopy={() => handleCopy(index)}>
                                            <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                              <FaCopy />
                                            </button>
                                          </CopyToClipboard>
                                        </OverLayAddressTooltip>
                                        {copied && copiedValue === index && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                      </>
                                    )}
                                  </td>
                                  <td>{data.country ? data.country : "N/A"}</td>
                                  <td>{data.ip ? data.ip : "N/A"}</td>
                                  <td>{moment(data.lastLoginDate).format("DD-MM-YYYY hh:mm A") || "N/A"}</td>
                                  <td>{highValue}</td>
                                  <td>{totalActivities}</td>
                                  <td className="under-dropdown">
                                    {
                                      data.isActive == 0 ?
                                        <div className="completed-transation failed">
                                          Deactivated
                                        </div>
                                        :
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant={data.isActive == 0 ? "danger" : data.isActive == 1 ? "success" : "secondary"}
                                            id="dropdown-basic">
                                            {data.isActive == 0 ? "Deactivated" : data.isActive == 1 ? "Activated" : "Suspended"}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {
                                              dropdownList.map((item) => (
                                                data.isActive !== item.id &&
                                                <Dropdown.Item
                                                  onClick={() => handleStatusChange(item, data._id)}
                                                >
                                                  {item.name}
                                                </Dropdown.Item>
                                              ))
                                            }
                                          </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                  </td>
                                  <td>
                                    <Link
                                      to={`/user-activities/${data._id}`}
                                      state={{ userWallerAddress: data.walletAddress ? data.walletAddress : '' }}
                                    >
                                      <div className="view-btn-main" style={{ cursor: 'pointer' }}>
                                        < i className="fa-solid fa-eye"></i>
                                      </div>
                                    </Link>
                                  </td>
                                </tr>
                              )
                            })) : (
                            <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="no-data-image"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                              </td>
                            </tr>
                          )}
                    </tbody>
                  </Table>
                  {userListing.length > 0 && totalItems > 10 && !loader ?
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                    : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
