import { useEffect, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initializeApp } from '@firebase/app';
// import { GlobalContext } from '../globalStates.js/GlobalContext';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { GlobalContext } from '../globalState/GlobalContext';

// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyB7P0q7QZD9mC1p7fCtULx3XENErS04iv4",
  authDomain: "pineapple-e77fe.firebaseapp.com",
  projectId: "pineapple-e77fe",
  storageBucket: "pineapple-e77fe.appspot.com",
  messagingSenderId: "698092437776",
  appId: "1:698092437776:web:a538f9df4d021f433aa595",
  measurementId: "G-Z7M7Z5JTND"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const useNotificationSetup = () => {
  const globalStates = useContext(GlobalContext);

  useEffect(() => {
    const setupNotifications = async () => {
      try {
        // Request permission for notifications
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          const messaging = getMessaging();
          const token = await getToken(messaging);
          console.log('FCM Token:', token);
          globalStates.setFcmToken(token);
          localStorage.setItem('fcmToken', token);
        } else {
          console.log('Notification permission denied.');
        }
        // Handle foreground notifications
        onMessage(messaging, (payload) => {
          console.log('Foreground Message:', payload.notification.title, payload);
          globalStates.setManagenotification(!globalStates.managenotification);
          toast.info(`New Notification: ${payload.notification.title}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 'success_draft_1',
          });
        });
      } catch (error) {
        console.error('Error setting up notifications:', error);
      }
    };

    setupNotifications();
  }, [globalStates]);
};
export default useNotificationSetup;

