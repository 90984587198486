import { Container, Dropdown, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../globalState/GlobalContext";
import { useState } from "react";
import { pineappleDexService } from "../service/api.service";
import moment from "moment";
import useNotificationSetup, { setupNotifications } from '../config/firebase.js';
import { sendNativeNotification, toastNotification } from '../components/firebaseNotification/notificationHelpers.js';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';



const Header = () => {
  const navigate = useNavigate();
  const globalStates = useContext(GlobalContext);
  const [loader, setLoader] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);

  async function Logout() {

    const params = {
      fcmToken: globalStates?.fcmToken
    }
    try {
      const response = await pineappleDexService.signOut(params);
      if (response.status == 200) {
        window.location.href = "/";
        localStorage.clear()
      }
    }
    catch (error) {
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    getUnReadNotification(page, "", false, "");
  }, []);


  useEffect(() => {
    if (globalStates.managenotification) {
      // getUnReadNotification(page, "", "false", "");
      getUnReadNotification(page, "", false, "");
    }
  }, [globalStates.managenotification]);


  async function getUnReadNotification(page, priority, status, sortOrder) {
    setLoader(true);
    try {
      const response = await pineappleDexService.getNotificationList(page, priority, status, sortOrder);
      if (response.status == 200) {
        setNotificationData(response.data?.data);
        setTotalItems(response?.data?.data?.totalDocuments);
        globalStates.setManagenotification(!globalStates.managenotification);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoader(false);
    }
  }

  function handleApiError(error) {
    setLoader(false);
    if (error?.response?.status === 401) {
      swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
        localStorage.clear();
        window.location.href = "/";
      });
    } else {
      swal({
        icon: 'error',
        text: error?.response?.data?.message || error?.message,
        button: "OK"
      });
    }
  }
  const maxVisibleItems = 5;

  const handleClick = (item) => {
    navigate(`/notification-security-detail/${item?._id}`, {
      state: {
        alertType: item.alertType || '',
        userId: item._id || '',
      },
    });
    globalStates.setNotificationUnRead(false)
  };

  async function handleAllMarkAsResolved(alertId, status) {
    const params = {
      // id: alertId,
      all: status,
    }
    setLoader(true);
    try {
      const response = await pineappleDexService.resolveNotification(params);
      if (response.status === 200) {
        getUnReadNotification(page, "", false, "");
        globalStates.setResolveAllNotification(!globalStates.resolveAllNotification);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoader(false);
    }
  }

  const modalRef = useRef(null);

  // Handle click outside of modal
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        globalStates.setNotificationUnRead(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);


  return (
    <>
      <div className="admin-header">
        <Container fluid>
          <div className="header-right">
            <div className="notification-top">
              <img onClick={() => globalStates.setNotificationUnRead(!globalStates.notificationUnRead)} src={require("../assets/images/notification.svg").default} />
              {totalItems > 0 && <p>{totalItems}</p>}
              {globalStates.notificationUnRead &&
                <div className="notification-menu" ref={modalRef}>
                  <div className="notification-menu-header">
                    <div className="notification-top-left-box">
                      <h6>Notifications</h6>
                      {notificationData?.eventAlert?.length > 0 &&
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`}>
                              <Form.Check
                                type={type}
                                id={`default-${type}`}
                                label={`Mark All Resolve`}
                                onClick={async () => {
                                  const result = await Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You want to resolve all alerts at once?",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, resolve it!',
                                    cancelButtonText: 'No, keep it'
                                  });

                                  if (result.isConfirmed) {
                                    await handleAllMarkAsResolved("", true); 
                                  }
                                }}
                              />
                            </div>
                          ))}
                        </Form>}
                    </div>

                    <div className="notification-menu-cross" onClick={() => globalStates.setNotificationUnRead(false)}>
                      <img className="notification-white-cross" src={require("../assets/images/white-cross.svg").default} alt="img" />
                    </div>

                  </div>
                  <div>
                    <ul>

                      {notificationData?.eventAlert?.length > 0 ?
                        notificationData?.eventAlert?.slice(0, maxVisibleItems).map((item, index) => {
                          return (
                            <li key={index}>
                              <div className="notification-item" onClick={() => handleClick(item)}>
                                <div className="title-text"> {item?.subject || '-'}</div>
                                <div className="description-text"> {item?.message || '-'}</div>
                                <div className="date-text">
                                  {item?.createdAt ? moment(item?.createdAt).fromNow() : '-'}
                                </div>
                              </div>
                            </li>
                          );
                        }) :
                        <div className="no-data-area notification-section-main">
                          <img src={require("../assets/images/no-data.svg").default} alt="img" />
                          <h3>No Notification available.</h3>
                        </div>
                      }
                    </ul>
                    {notificationData?.eventAlert?.length > 0 && notificationData?.eventAlert?.length > maxVisibleItems && (
                      <button onClick={() => { navigate("/notification-security"); globalStates.setNotificationUnRead(false) }}>
                        View All
                      </button>
                    )}
                  </div>

                </div>}
            </div>
            <div className="position-reltive under-search-header">
              {/* <input type="text" placeholder="Search here..." />
              <i className="fa fa-search" aria-hidden="true"></i> */}
            </div>
            <div className="logout-btn">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={require("../assets/images/user.png")} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => navigate("/change-password")}
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      swal({
                        title: 'Are you sure?',
                        text: 'you want to proceed with the logout?',
                        icon: 'warning',
                        buttons: ['Cancel', 'Yes, logout'],
                        dangerMode: true,
                      }).then((confirmed) => {
                        if (confirmed) {
                          Logout()
                        }
                      })
                    }}
                  >
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Header;
